import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
// import { LinkContainer } from 'react-router-bootstrap';
import { Auth } from "aws-amplify";
import Routes from "./Routes";
import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      userAccessRole: "",
    };
  }

  // TODO: dev branch change just to get on cicd for now
  // TODO: test branch change just to get on cicd for now
  // TODO: demo branch change just to get on cicd for now
  // Use componentDidMount() to load the user session with Amplify
  async componentDidMount() {
    try {
      // returns a promise, rest of app will wait for this response
      // from amplify auth with cognito
      await Auth.currentSession();
      // if 200 OK from above with session info then execute next
      this.userHasAuthenticated(true);

      // if OK pull role from cognito custom attribute and store in state
      this.userHasPermission("admin");
    } catch (err) {
      if (err !== "No current user") {
        // eslint-disable-next-line
        alert(err);
      }
    }
    // loading user session is asyn process, we need to make sure
    // that our app does not change state when it first loads
    // we wait to render until isAuthenticating is false
    this.setState({
      isAuthenticating: false,
    });
  }

  userHasPermission = (accessRole) => {
    this.setState({
      userAccessRole: accessRole,
    });
  };

  userHasAuthenticated = (authenticated) => {
    this.setState({
      isAuthenticated: authenticated,
    });
  };

  // eslint-disable-next-line
  handleLogout = async (event) => {
    // returns a promise that will clear user session for logout
    await Auth.signOut();
    this.userHasAuthenticated(false);
    // use history.push from react-router4 to redirect
    // user during login flow
    this.props.history.push("/login");
  };

  render() {
    const childProps = {
      // below is authentication provided by cognito
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      // Below is permissions available and passed as props
      // authorization!!
      userAccessRole: this.state.userAccessRole,
      userHasPermission: this.userHasPermission,
    };

    return (
      !this.state.isAuthenticating && (
        <div className="App container">
          <Navbar className="app-navi-bar" bg="light" expand="lg">
            {/* This is where the Logo is situated in the Nav bar */}
            <Navbar.Brand as={NavLink} to="/">
              <Image
                src="../../img/Logo.QAT.png"
                className="logo"
                responsive="true"
              />
            </Navbar.Brand>
            {/* This is toggle control for the responsive menu */}
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            {/* This is the nav collapse for mobile */}
            <Navbar.Collapse id="basic-navbar-nav">
              {/* FIXME: Need to find out how new pullRight works after the update to B4 */}
              <Nav className="nav-in-collapse mr-auto">
                {/* Here are the login dropdown menu choices in the new nav bar */}
                <NavDropdown title="CRM" id="basic-nav-dropdown">
                  {this.state.isAuthenticated ? (
                    <NavDropdown.Item
                      onClick={this.handleLogout}
                      className="navi-link"
                    >
                      Logout
                    </NavDropdown.Item>
                  ) : (
                    <Fragment>
                      {/* Fragment is like placeholder component */}
                      <Nav.Link
                        as={NavLink}
                        to="/register"
                        className="navi-link"
                        exact
                      >
                        Register
                      </Nav.Link>
                      <Nav.Link
                        as={NavLink}
                        to="/login"
                        className="navi-link"
                        exact
                      >
                        Login
                      </Nav.Link>
                    </Fragment>
                  )}
                </NavDropdown>
                {/* Here are the feature routes in the Navbar */}
                <Nav.Link
                  as={NavLink}
                  to="/locations"
                  className="navi-link"
                  exact
                >
                  Locations
                </Nav.Link>
                <Nav.Link as={NavLink} to="/renew" className="navi-link" exact>
                  Online AutoTags
                </Nav.Link>
                <Nav.Link
                  as={NavLink}
                  to="/vin-verification"
                  className="navi-link"
                >
                  VIN Verify
                </Nav.Link>
                <Nav.Link
                  as={NavLink}
                  to="/ca-dmv-services"
                  className="navi-link"
                >
                  CA DMV Services
                </Nav.Link>
                {/* Here are the CRM dropdown menu choices in the nav bar */}
                <NavDropdown title="Solutions" id="basic-nav-dropdown">
                  <Nav.Link
                    as={NavLink}
                    to="/dealers"
                    className="navi-link"
                    exact
                  >
                    For Dealers
                  </Nav.Link>
                  <Nav.Link
                    as={NavLink}
                    to="/fleets"
                    className="navi-link"
                    exact
                  >
                    For Fleets
                  </Nav.Link>
                  <Nav.Link
                    as={NavLink}
                    to="/tag-agencies"
                    className="navi-link"
                    exact
                  >
                    AutoTag Agencies
                  </Nav.Link>
                  <NavDropdown.Divider />
                  <Nav.Link
                    as={NavLink}
                    to="/crm-features"
                    className="navi-link"
                    exact
                  >
                    CRM Platform Features
                  </Nav.Link>
                </NavDropdown>
                {/* Customer Support Route */}
                <Nav.Link
                  as={NavLink}
                  to="/customer-support"
                  className="navi-link"
                >
                  Support
                </Nav.Link>
              </Nav>
              {/* Here is the inline search bar in the navigation bar */}
              <Form inline id="search-form">
                <FormControl
                  type="text"
                  placeholder="Search"
                  className="mr-sm-2"
                />
                <Button
                  id="search-btn"
                  className="btn btn-sm"
                  variant="outline-success"
                >
                  Search
                </Button>
              </Form>
            </Navbar.Collapse>
          </Navbar>
          {/* These are the application's routes */}
          <Routes childProps={childProps} />
        </div>
      )
    );
  }
}

// export with a HOC to give app access to router props
// access the history object's props and the closest
// <Routes>'s match withRouter().
export default withRouter(App);
