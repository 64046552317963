// Configure dev and PROD for CICD
// FIXME: Implement using process.env for prod!

const dev = {
  s3: {
    // QAT Credentials as deployed in serverless for CRM case-records
    // Use west coast servers!!!!!
    REGION: "us-west-2",
    BUCKET: "qat-crm-uploads-dev-attachmentsbucket-8oa9gkt4m78c"
  },

  apiGateway: {
    REGION: "us-west-2",
    URL: "https://x1fiopu6oh.execute-api.us-west-2.amazonaws.com/dev",
    AVRS_URL: "https://renew.qatcrm.com"
  },

  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_MntYvBzuX",
    APP_CLIENT_ID: "376ppre22tb8rdna49mn8orvg9",
    IDENTITY_POOL_ID: "us-west-2:2cfa03a3-6167-406e-a64e-9d409af7b48d"
  },

  Places_API_KEY: "AIzaSyADJElWb4ZHSrfqko3De-53VoWPI9sCCz4",
  APPROVAL_CODE: "qat_testing_only"
};

const prod = {
  s3: {
    // QAT Credentials as deployed in serverless for CRM case-records
    // Use west coast servers!!!!!
    REGION: "us-west-2",
    BUCKET: "qat-crm-uploads-dev-attachmentsbucket-8oa9gkt4m78c"
  },

  apiGateway: {
    REGION: "us-west-2",
    URL: "https://x1fiopu6oh.execute-api.us-west-2.amazonaws.com/dev",
    AVRS_URL: "https://renew.qatcrm.com"
  },

  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_MntYvBzuX",
    APP_CLIENT_ID: "376ppre22tb8rdna49mn8orvg9",
    IDENTITY_POOL_ID: "us-west-2:2cfa03a3-6167-406e-a64e-9d409af7b48d"
  },

  Places_API_KEY: "AIzaSyADJElWb4ZHSrfqko3De-53VoWPI9sCCz4",
  APPROVAL_CODE: "qat_testing_only"
};

const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

// QAT Credentials as deployed in serverless for CRM case-records
export default {
  MAX_ATTACHMENT_SIZE: 5000000,

  ...config
};
