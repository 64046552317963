import React, { Component, Fragment } from "react";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import { LinkContainer } from "react-router-bootstrap";
// import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import SplitButton from "react-bootstrap/SplitButton";
import Container from "react-bootstrap/Container";
import Media from "react-media";
import "./DashboardUiCard.css";

export default class DashboardUiCard extends Component {
	constructor(props){
		super(props);

		this.state = {
			display: true,
			title: "",
      corpName: ""
		};

    this.getQatCrmIdList = this.getQatCrmIdList.bind(this);
	}

	async componentDidMount() {
		this.setState({
			title: "",
      corpName: "Select Company"
		});
	}

  formateDateStrOutput(dateIn) {

    const month = new Date(dateIn).getMonth().toLocaleString();
    const dt = new Date(dateIn).getDate().toLocaleString();
    const yr = new Date(dateIn).getFullYear();


    const dateOut = month + "/" + dt + "/" + yr;
    return dateOut;
  }

  renderUsersListCard(users) {
    return [{}].concat(users).map(
      (userRecord, i) => (i !== 0)
        ? <LinkContainer key={ userRecord.userName } to={ `/users/${userRecord.qatCrmId}/${userRecord.userName}` }>
            <ListGroup.Item id="card-group" action>
              <strong><p className="list-item">{ userRecord.lastName + ", " + userRecord.firstName }</p></strong>
              <p className="list-date">{ "Created: " + this.formateDateStrOutput(userRecord.createdAt) }</p>
            </ListGroup.Item>
          </LinkContainer>
        : <LinkContainer key="new" to="/case-records/new">
            <ListGroup.Item>
              <h5><b>{ "\uFF0B" }</b> New User</h5>
            </ListGroup.Item>
          </LinkContainer>
    );
  }

  renderCaseRecordsListCard(cases) {
    return [{}].concat(cases).map(
      (caseRecord, i) => (i !== 0)
        ? <LinkContainer key={ caseRecord.caseRecordId } to={ `/case-records/${caseRecord.qatCrmId}/${caseRecord.caseRecordId}` }>
            <ListGroup.Item id="card-group" action>
              <strong><p className="list-item">{ caseRecord.subject }</p></strong>
              <p className="list-date">{ "Created: " + this.formateDateStrOutput(caseRecord.createdAt) }</p>
            </ListGroup.Item>
          </LinkContainer>
        : <LinkContainer key="new" to="/case-records/new">
            <ListGroup.Item>
              <h5><b>{ "\uFF0B" }</b> New Case</h5>
            </ListGroup.Item>
          </LinkContainer>
    );
  }

  renderKbArticlesCard(articles) {
    return [{}].concat(articles).map(
      (Article, i) => (i !== 0)
        ? <LinkContainer key={ Article.articleId } to={ `/knowledge-base/${Article.qatCrmId}/${Article.articleId}` }>
            <ListGroup.Item id="card-group" action>
              <strong><p className="list-item">{ Article.kbSubject }</p></strong>
              <p className="list-date">{ "Created: " + this.formateDateStrOutput(Article.createdAt) }</p>
            </ListGroup.Item>
          </LinkContainer>
        : <LinkContainer key="new" to="/knowledge-base/new">
            <ListGroup.Item>
              <h5><b>{ "\uFF0B" }</b> New Article</h5>
            </ListGroup.Item>
          </LinkContainer>
    );
  }

  renderTransactionsListCard(cases) {
    return [{}].concat(cases).map(
      (caseRecord, i) => (i !== 0)
        ? <LinkContainer key={ caseRecord.caseRecordId } to={ `/case-records/${caseRecord.qatCrmId}/${caseRecord.caseRecordId}` }>
            <ListGroup.Item id="card-group" action>
              <strong><p className="list-item">{ caseRecord.subject }</p></strong>
              <p className="list-date">{ "Created: " + this.formateDateStrOutput(caseRecord.createdAt) }</p>
            </ListGroup.Item>
          </LinkContainer>
        : <LinkContainer key="new" to="/case-records/new">
            <ListGroup.Item>
              <h5><b>{ "\uFF0B" }</b> New Transaction</h5>
            </ListGroup.Item>
          </LinkContainer>
    );
  }

  // TODO: Need a list return of bothe qatCrmID and UserName
  /*
   * @param qatCrmId
   * @corpName
   * return [qatCrmId, corpName] <string>




   .map(
      (userRecord, i) => (i !== 0)
        ? ar.push({ qatId: userRecord.qatCrmId, cName: userRecord.corpName })
        : ar.push({})
    );
   */
  getQatCrmIdList(userRecords) {

    // [ qatCrmId, userName ]
    let ar = [{}].concat(userRecords).map(
      (userRecord, i) => (i !== 0)
        ? [userRecord.qatCrmId, userRecord.lastName]
        : ""
    );


    console.log("This is the list qatCrmId: ", ar);

    return ar;
  }

  getUserNameList(userRecords) {

    // [ qatCrmId, corpName ]
    let ar = [{}].concat(userRecords).map(
      (userRecord, i) => (i !== 0)
        ? [userRecord.qatCrmId, userRecord.corpName]
        : ""
    );


    console.log("This is the list qatCrmId: ", ar);

    return ar;
  }

  // TODO: Need to redo and loop through every case in db to list properly
  renderUiCards() {
    const ar1 = this.getQatCrmIdList( this.props.users );
    const ar2 = this.getUserNameList( this.props.users );

    return (
      <div>
        <Media query="(min-width: 319px) and (max-width: 551px)">
          <Container fluid="True">

            <Container className="header-container" fluid="True">



              {/* NOTE: Users Card */}
              <article className="search-container">
                <div className="search-card">
                  <h5 className="user-title-card">Select Records</h5>

                  <Container className="toolbar-container-sm" fluid="True">
                    <Form>
                    { /* This is the org type */ }
                    <Form.Group controlId="corpName">

                      <Form.Control as="select"
                        className="dropdown-options"
                        value={ this.state.corpName }
                        onChange={ this.handleChange }
                        required
                        pattern="Dealer|Fleet|Transport Co.|ROV|Insurance Co.|Franchise|Courier|Corporate">
                        <option>
                          Select Company
                        </option>

                        { ar2.map((item) =>
                            <Fragment key={ `${ item[1] }` }>

                              <option>
                                {item[1]}
                              </option>

                            </Fragment>
                          )
                        }

                      </Form.Control>
                    </Form.Group>

                    <ButtonToolbar>
                      {
                        ["Company"].map( entity => (

                          <SplitButton
                            drop="left"
                            className="split-button-search"
                            onClick={ void (0) }
                            onTouchStart={ () => "" }
                            title={ this.state.corpName }
                            id="dropdown-button-drop-left"
                            key={`${ entity }`}

                          >

                            { /* this.createDropDownList() */ }
                            { /* TODO: Need to output a list of Company & User */ }

                            <Dropdown.Header>Select a Company Record</Dropdown.Header>

                            { ar2.map((item) =>
                                <Fragment key={ `${ item[1] }` }>
                                  <Dropdown.Item eventKey={ `${ item[1] }` }>{item[1]}</Dropdown.Item>
                                  <Dropdown.Divider />
                                </Fragment>
                            )}

                          </SplitButton>
                        ))
                      }
                    </ButtonToolbar>



                    <ButtonToolbar>
                      {
                        ["User"].map( entity => (

                          <SplitButton
                            drop="left"
                            className="split-button-search"
                            onClick={ void (0) }
                            onTouchStart={ () => "" }
                            title={`Select ${ entity }`}
                            id="dropdown-button-drop-left"
                            key={entity}
                          >

                            { /* this.createDropDownList() */ }
                          { /* TODO: Need to output a list of Company & User */ }


                            <Dropdown.Header>Select a User Record</Dropdown.Header>

                            { ar1.map((item) =>
                                <Fragment key={ `${ item[1] }` }>
                                  <Dropdown.Item eventKey={ `${ item[1] }` }>{item[1]}</Dropdown.Item>
                                  <Dropdown.Divider />
                                </Fragment>
                            )}

                          </SplitButton>

                        ))
                      }
                    </ButtonToolbar>
                    </Form>
                  </Container>
                </div>
              </article>

            </Container>



            <Container className="dash-container" fluid="True">

              {/* TODO: Users Card needed */}
              <article className="card-container-sm">
                <div className="ui-card">
                  <h3 className="title-card">Users</h3>
                  <hr></hr>
                  <ListGroup className="card-lists">
                    { !this.state.isLoading && this.renderUsersListCard(this.props.users) }
                  </ListGroup>
                </div>
              </article>

              {/* NOTE: Cases Card */}
              <article className="card-container-sm">
                <div className="ui-card">
                  <h3 className="title-card">Case Records</h3>
                  <hr></hr>
                  <ListGroup className="card-lists">
                    { !this.state.isLoading && this.renderCaseRecordsListCard(this.props.cases) }
                  </ListGroup>
                </div>
              </article>

              {/* NOTE: Articles Card */}
              <article className="card-container-sm">
                <div className="ui-card">
                  <h3 className="title-card">Articles</h3>
                  <hr></hr>
                  <ListGroup className="card-lists">
                    { !this.state.isLoading && this.renderKbArticlesCard(this.props.articles) }
                  </ListGroup>
                </div>
              </article>

              {/* TODO: Transaction Card needed */}
              <article className="card-container-sm">
                <div className="ui-card">
                  <h3 className="title-card">Transactions</h3>
                  <hr></hr>
                  <ListGroup className="card-lists">
                    { !this.state.isLoading && this.renderTransactionsListCard(this.props.cases) }
                  </ListGroup>
                </div>
              </article>
            </Container>
          </Container>
        </Media>






        <Media query="(min-width: 551px) and (max-width: 568px)">
          <Container fluid="True">

            <Container className="header-container" fluid="True">




              {/* NOTE: Users Card */}
              <article className="search-container">
                <div className="search-card">
                  <h5 className="user-title-card">Select Records</h5>

                  <Container className="toolbar-container" fluid="True">

                    <ButtonToolbar>
                      {
                        ["Company"].map( entity => (

                          <SplitButton
                            drop="left"
                            className="split-button-search"
                            onClick={ void (0) }
                            onTouchStart={ () => "" }
                            title={`Select ${ entity }`}
                            id="dropdown-button-drop-left"
                            key={entity}
                          >

                            { /* this.createDropDownList() */ }
                          { /* TODO: Need to output a list of Company & User */ }


                            <Dropdown.Header>Select a Company Record</Dropdown.Header>

                            { ar2.map((item) =>
                                <Fragment key={ `${ item[1] }` }>
                                  <Dropdown.Item eventKey={ `${ item[1] }` }>{item[1]}</Dropdown.Item>
                                  <Dropdown.Divider />
                                </Fragment>
                            )}

                          </SplitButton>

                        ))
                      }
                    </ButtonToolbar>



                    <ButtonToolbar>
                      {
                        ["User"].map( entity => (

                          <SplitButton
                            drop="left"
                            className="split-button-search"
                            onClick={ void (0) }
                            onTouchStart={ () => "" }
                            title={`Select ${ entity }`}
                            id="dropdown-button-drop-left"
                            key={entity}
                          >

                            { /* this.createDropDownList() */ }
                          { /* TODO: Need to output a list of Company & User */ }


                            <Dropdown.Header>Select a User Record</Dropdown.Header>

                            { ar1.map((item) =>
                                <Fragment key={ `${ item[1] }` }>
                                  <Dropdown.Item eventKey={ `${ item[1] }` }>{item[1]}</Dropdown.Item>
                                  <Dropdown.Divider />
                                </Fragment>
                            )}

                          </SplitButton>

                        ))
                      }
                    </ButtonToolbar>

                  </Container>

                </div>
              </article>

            </Container>





            <Container className="dash-container" fluid="True">

              {/* TODO: Users Card needed */}
              <article className="card-container-sm">
                <div className="ui-card">
                  <h3 className="title-card">Users</h3>
                  <hr></hr>
                  <ListGroup className="card-lists">
                    { !this.state.isLoading && this.renderUsersListCard(this.props.users) }
                  </ListGroup>
                </div>
              </article>

              {/* NOTE: Cases Card */}
              <article className="card-container-sm">
                <div className="ui-card">
                  <h3 className="title-card">Case Records</h3>
                  <hr></hr>
                  <ListGroup className="card-lists">
                    { !this.state.isLoading && this.renderCaseRecordsListCard(this.props.cases) }
                  </ListGroup>
                </div>
              </article>

              {/* NOTE: Articles Card */}
              <article className="card-container-sm">
                <div className="ui-card">
                  <h3 className="title-card">Articles</h3>
                  <hr></hr>
                  <ListGroup className="card-lists">
                    { !this.state.isLoading && this.renderKbArticlesCard(this.props.articles) }
                  </ListGroup>
                </div>
              </article>

              {/* TODO: Transaction Card needed */}
              <article className="card-container-sm">
                <div className="ui-card">
                  <h3 className="title-card">Transactions</h3>
                  <hr></hr>
                  <ListGroup className="card-lists">
                    { !this.state.isLoading && this.renderTransactionsListCard(this.props.cases) }
                  </ListGroup>
                </div>
              </article>
            </Container>

            </Container>
        </Media>








        <Media query="(min-width: 569px)">
          <Container fluid="True">

            <Container className="header-container" fluid="True">




              {/* NOTE: Users Card */}
              <article className="search-container">
                <div className="search-card">
                  <h5 className="user-title-card">Select Records</h5>

                  <Container className="toolbar-container" fluid="True">

                    <ButtonToolbar>
                      {
                        ["Company"].map( entity => (

                          <SplitButton
                            drop="left"
                            className="split-button-search"
                            onClick={ void (0) }
                            onTouchStart={ () => "" }
                            title={`Select ${ entity }`}
                            id="dropdown-button-drop-left"
                            key={entity}
                          >

                            { /* this.createDropDownList() */ }
                          { /* TODO: Need to output a list of Company & User */ }


                            <Dropdown.Header>Select a Company Record</Dropdown.Header>

                            { ar2.map((item) =>
                                <Fragment key={ `${ item[1] }` }>
                                  <Dropdown.Item eventKey={ `${ item[1] }` }>{item[1]}</Dropdown.Item>
                                  <Dropdown.Divider />
                                </Fragment>
                            )}

                          </SplitButton>

                        ))
                      }
                    </ButtonToolbar>



                    <ButtonToolbar>
                      {
                        ["User"].map( entity => (

                          <SplitButton
                            drop="left"
                            className="split-button-search"
                            onClick={ void (0) }
                            onTouchStart={ () => "" }
                            title={`Select ${ entity }`}
                            id="dropdown-button-drop-left"
                            key={entity}
                          >

                            { /* this.createDropDownList() */ }
                          { /* TODO: Need to output a list of Company & User */ }


                            <Dropdown.Header>Select a User Record</Dropdown.Header>

                            { ar1.map((item) =>
                                <Fragment key={ `${ item[1] }` }>
                                  <Dropdown.Item eventKey={ `${ item[1] }` }>{item[1]}</Dropdown.Item>
                                  <Dropdown.Divider />
                                </Fragment>
                            )}

                          </SplitButton>

                        ))
                      }
                    </ButtonToolbar>

                  </Container>

                </div>
              </article>

            </Container>


            <Container className="dasher-container" fluid="True">

              {/* NOTE: Users Card */}
              <article className="card-container">
                <div className="ui-card">
                  <h3 className="title-card">Users</h3>
                  <hr></hr>
                  <ListGroup className="card-lists">
                    { !this.state.isLoading && this.renderUsersListCard(this.props.users) }
                  </ListGroup>
                </div>
              </article>

              {/* NOTE: Cases Card */}
              <article className="card-container">
                <div className="ui-card">
                  <h3 className="title-card">Case Records</h3>
                  <hr></hr>
                  <ListGroup className="card-lists">
                    { !this.state.isLoading && this.renderCaseRecordsListCard(this.props.cases) }
                  </ListGroup>
                </div>
              </article>

              {/* NOTE: Articles Card */}
              <article className="card-container">
                <div className="ui-card">
                  <h3 className="title-card">Articles</h3>
                  <hr></hr>
                  <ListGroup className="card-lists">
                    { !this.state.isLoading && this.renderKbArticlesCard(this.props.articles) }
                  </ListGroup>
                </div>
              </article>

              {/* TODO: Transaction Card needed */}
              <article className="card-container">
                <div className="ui-card">
                  <h3 className="title-card">Transactions</h3>
                  <hr></hr>
                  <ListGroup className="card-lists">
                    { !this.state.isLoading && this.renderTransactionsListCard(this.props.cases) }
                  </ListGroup>
                </div>
              </article>
            </Container>
          </Container>
        </Media>
      </div>
    );
  }

  render() {
    return (
      <section className="card-details">
        { this.renderUiCards() }
      </section>
     );
  }
}

