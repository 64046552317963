import React, { Component } from "react";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import Media from "react-media";
import "./HorizonMenu.css";

export default class HorizonViews extends Component {
	render() {
		return (
				<Media query={`(min-width: ${this.props.minW}px) and (max-width: ${this.props.maxW}px) and (min-height: ${this.props.minH}px) and (max-height: ${this.props.maxH}px)`}>
					<div>
						<h2 className={`${this.props.classTitle}`}>Features Available</h2>
						<article className="horizon-flexbox">
							<Link to="/register" className="horizon-service-block">
								<section className="horizon-card" onClick={ void (0) } onTouchStart={ () => "" }>
									<section>
										<Image src="" className="horizon-icon" />
									</section>

									<h5 className="horizon-card-title">Registration Renewals</h5>

									<p id="p" className="horizon-card-tagline">
										Avoid the Line and Renew Online today! Get your updated license plate registration sticker and
										your free Registration e-Card direct from the California DMV right now.
									</p>
								</section>
							</Link>

							<Link to="/register" className="horizon-service-block">
								<section className="horizon-card" onClick={ void (0) } onTouchStart={ () => "" }>
									<section>
										<Image src="" className="horizon-icon" />
									</section>

									<h5 className="horizon-card-title">Title Transfers</h5>

									<p id="p" className="horizon-card-tagline">
										Quickly change your vehicle's ownership with our easy to use DVM Title Transfer process online today.
										Buy your car with no hassle and close the deal now!
									</p>
								</section>
							</Link>

							<Link to="/register" className="horizon-service-block">
								<section className="horizon-card" onClick={ void (0) } onTouchStart={ () => "" }>
									<section>
										<Image src="" className="horizon-icon" />
									</section>

									<h5 className="horizon-card-title">VIN Verifications</h5>

									<p id="p" className="horizon-card-tagline">
										Maintain compliance with federal safety requirements and smog emission standards. VIN verification is required
										prior to vehicle registration in California to ensure that the VIN has not been altered and matches the titling documents.
									</p>
								</section>
							</Link>

							<Link to="/register" className="horizon-service-block">
								<section className="horizon-card" onClick={ void (0) } onTouchStart={ () => "" }>
									<section>
										<Image src="" className="horizon-icon" />
									</section>

									<h5 className="horizon-card-title">Batch Renewals</h5>

									<p id="p" className="horizon-card-tagline">
										Permanent Fleet Registration (PFR) program members and owners of large vehicle fleets can maintain their vehicle registration
										and process batch renewals with Quick Auto Tags.
									</p>
								</section>
							</Link>

							<Link to="/register" className="horizon-service-block">
								<section className="horizon-card" onClick={ void (0) } onTouchStart={ () => "" }>
									<section>
										<Image src="" className="horizon-icon" />
									</section>

									<h5 className="horizon-card-title">Dealer & Fleet CRM</h5>

									<p id="p" className="horizon-card-tagline">
										Review your transactions, review the status of your title transfers, and get support not generally available at Ca DMV. register
										with us a see how much easier the QuickAutoTags Dealer CRM makes your life so you can take care of what matters most to you.
									</p>
								</section>
							</Link>

							<Link to="/register" className="horizon-service-block">
								<section className="horizon-card" onClick={ void (0) } onTouchStart={ () => "" }>
									<section>
										<Image src="" className="horizon-icon" />
									</section>

									<h5 className="horizon-card-title">Online DMV Services</h5>

									<p id="p" className="horizon-card-tagline">
										Avoid the lines and go online with Quick Auto Tags to resolve all of your DMV issues today. Quick Auto Tags DMV Registrtion offices
										provide you with same day service for plates, stickers, tags and much more. Call today!
									</p>
								</section>
							</Link>
						</article>
					</div>
				</Media>

		);
	}
}
