import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import "./RegistrationProgressAside.css";


export default class RegistrationProgressAside extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };

  }

  renderAside() {
    let clsName1;
    let clsName2;
    let clsName3;
    let clsName4;

    if (this.props.registrationStep.toString() === "1") {
      clsName1 = "listStep";
      clsName2 = "list";
      clsName3 = "list";
      clsName4 = "list";
    } else if (this.props.registrationStep.toString() === "2") {
      clsName1 = "list";
      clsName2 = "listStep";
      clsName3 = "list";
      clsName4 = "list";
    } else if (this.props.registrationStep.toString() === "3") {
      clsName1 = "list";
      clsName2 = "list";
      clsName3 = "listStep";
      clsName4 = "list";
    } else if (this.props.registrationStep.toString() === "4") {
      clsName1 = "list";
      clsName2 = "list";
      clsName3 = "list";
      clsName4 = "listStep";
    }

    return (
      <aside>
        <h4 className="progress-hdr1">Registration Progress</h4>
        <h5 className="progress-hdr2">Registration Progress</h5>
        <h6 className="progress-hdr3">Registration Progress</h6>
        { /* TODO: Make these link back to earlier steps! */ }
        <ul id="registration-aside-menu">
          <li className={ `${clsName1}` }>
            1.
            { `${ this.props.orgType}` } Information
          </li>
          <li className={ `${clsName2}` }>
            2. Enter User Details
          </li>
          <li className={ `${clsName3}` }>
            3. Create Login Credentials
          </li>
          <li className={ `${clsName4}` }>
            4. Confirm Registration
          </li>
        </ul>
        <h4 className="progress-hdr1">Subscription Benefits</h4>
        <h5 className="progress-hdr2">Subscription Benefits</h5>
        <h6 className="progress-hdr3">Subscription Benefits</h6>
        <ul id="registration-aside-menu">
          <li className="list">
            Fleets get First Month Free!
          </li>
          <li className="list">
            No Registration Fees.
          </li>
          <li className="list">
            No Long Term Contract.
          </li>
          <li className="list">
            Unlimited Cloud Storage.
          </li>
          <li className="list">
            Unlimited Tech Support.
          </li>
          <li className="list">
            Automatic Updates.
          </li>
        </ul>
        <Form.Text className="help-text">
          Contact User Support: (305) 527-7237
        </Form.Text>
      </aside>
      );
  }

  render() {
    return (
    this.renderAside()
    );
  }
}






