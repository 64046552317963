import React, { Component } from "react";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Media from "react-media";
import HorizonViews from "./HorizonViews.js";
import "./HorizonMenu.css";

export default class HorizonMenu extends Component {
	render() {
		return (
			// Trying to capture iPhoneSE Portrait mode
			<Container className="horizon-container" fluid="True">
				<HorizonViews
					minW="300"
					maxW="7379"
					minH="300"
					maxH="6585"
					classTitle="features-title-1"
				/>
{/*
				<HorizonViews
					minW="565"
					maxW="600"
					minH="319"
					maxH="360"
					classTitle="features-title-3"
				/>

				<HorizonViews
					minW="365"
					maxW="400"
					minH="586"
					maxH="690"
					classTitle="features-title-1a"
				/>

				<HorizonViews
					minW="595"
					maxW="699"
					minH="355"
					maxH="400"
					classTitle="features-title-4"
				/>

				<HorizonViews
					minW="371"
					maxW="385"
					minH="800"
					maxH="820"
					classTitle="features-title-11sP"
				/>

				<HorizonViews
					minW="775"
					maxW="860"
					minH="300"
					maxH="450"
					classTitle="features-title-11sL post"
				/>

				<HorizonViews
					minW="401"
					maxW="440"
					minH="641"
					maxH="769"
					classTitle="features-title-2"
				/>

				<HorizonViews
					minW="691"
					maxW="750"
					minH="401"
					maxH="420"
					classTitle="features-title-5"
				/>

				<HorizonViews
					minW="760"
					maxW="830"
					minH="1019"
					maxH="1060"
					classTitle="features-title-6"
				/>

				<HorizonViews
					minW="319"
					maxW="370"
					minH="1019"
					maxH="1060"
					classTitle="features-title-6"
				/>

				<HorizonViews
					minW="420"
					maxW="440"
					minH="1019"
					maxH="1060"
					classTitle="features-title-6"
				/>

				<HorizonViews
					minW="1018"
					maxW="1059"
					minH="759"
					maxH="829"
					classTitle="features-title-7"
				/>

				<HorizonViews
					minW="319"
					maxW="370"
					minH="759"
					maxH="829"
					classTitle="features-title-7"
				/>

				<HorizonViews
					minW="620"
					maxW="695"
					minH="759"
					maxH="829"
					classTitle="features-title-7"
				/>

				<HorizonViews
					minW="371"
					maxW="619"
					minH="759"
					maxH="829"
					classTitle="features-title-8"
				/>

				<HorizonViews
					minW="831"
					maxW="999"
					minH="1060"
					maxH="1155"
					classTitle="features-title-9"
				/>

				<HorizonViews
					minW="319"
					maxW="370"
					minH="1060"
					maxH="1155"
					classTitle="features-title-9"
				/>

				<HorizonViews
					minW="490"
					maxW="520"
					minH="1060"
					maxH="1155"
					classTitle="features-title-9"
				/>

				<HorizonViews
					minW="1025"
					maxW="1150"
					minH="830"
					maxH="925"
					classTitle="features-title-10"
				/>

				<HorizonViews
					minW="319"
					maxW="370"
					minH="830"
					maxH="925"
					classTitle="features-title-10"
				/>

				<HorizonViews
					minW="775"
					maxW="799"
					minH="830"
					maxH="925"
					classTitle="features-title-10"
				/>

				<HorizonViews
					minW="535"
					maxW="570"
					minH="830"
					maxH="925"
					classTitle="features-title-10"
				/>

				<HorizonViews
					minW="1000"
					maxW="1050"
					minH="1156"
					maxH="1399"
					classTitle="features-title-11"
				/>

				<HorizonViews
					minW="371"
					maxW="425"
					minH="1156"
					maxH="1399"
					classTitle="features-title-11"
				/>

				<HorizonViews
					minW="625"
					maxW="675"
					minH="1156"
					maxH="1399"
					classTitle="features-title-11"
				/>

				<HorizonViews
					minW="1156"
					maxW="1399"
					minH="1000"
					maxH="1050"
					classTitle="features-title-12"
				/>

				<HorizonViews
					minW="371"
					maxW="425"
					minH="1000"
					maxH="1050"
					classTitle="features-title-12"
				/>

				<HorizonViews
					minW="950"
					maxW="999"
					minH="1000"
					maxH="1050"
					classTitle="features-title-12"
				/>

				<HorizonViews
					minW="650"
					maxW="699"
					minH="1000"
					maxH="1050"
					classTitle="features-title-12"
				/>

				<HorizonViews
					minW="350"
					maxW="374"
					minH="630"
					maxH="650"
					classTitle="features-title-13"
				/>

				<HorizonViews
					minW="1400"
					maxW="1500"
					minH="700"
					maxH="800"
					classTitle="features-title-18"
				/>

				<HorizonViews
					minW="1350"
					maxW="1375"
					minH="760"
					maxH="775"
					classTitle="features-title-14"
				/>

				<HorizonViews
					minW="760"
					maxW="775"
					minH="1320"
					maxH="1375"
					classTitle="features-title-15"
				/>

				<HorizonViews
					minW="1900"
					maxW="1950"
					minH="1000"
					maxH="1100"
					classTitle="features-title-16"
				/>

				<HorizonViews
					minW="1050"
					maxW="1100"
					minH="1900"
					maxH="1950"
					classTitle="features-title-17"
				/>

				<HorizonViews
					minW="1951"
					maxW="9000"
					minH="1001"
					maxH="9950"
					classTitle="features-title-17"
				/>
*/}
			</Container>
		);
	}
}
