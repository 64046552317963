import React, { Component } from "react";
import { API, Storage } from "aws-amplify";
import { s3Upload } from "../libs/awsLib";
import Form from "react-bootstrap/Form";
//import FormCheck from "react-bootstrap/FormCheck";
//import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./KbArticle.css";
// Using react containers to achieve responsiveness in Medias
import Container from "react-bootstrap/Container";
import Media from "react-media";

export default class KbArticle extends Component {
  constructor(props) {
    super(props);
    this.file = null;

    /*
     * The following data will need to be inserted:
		  userId: event.requestContext.identity.cognitoIdentityId,
	      articleId: uuid.v1(),
	      subject: data.subject,
	      articleContent: data.articleContent,
	      category: data.category,
	      keywords: data.keywords,
	      attachment: data.attachment,
	      createdAt: Date.now()
     */

    this.state = {
      isLoading: null,
      isDeleting: null,
      article: null,
      attachmentURL: null,
      userId: "",
      userName: "",
      qatCrmId: "",
      articleId: "",
      kbSubject: "",
      articleContent: "",
      //category: "",
      //keywords: ""
    };
  }

  async componentDidMount() {
    try {
      let attachmentURL;
      // TODO: Need to make sure that all state data is here!!!
      const article = await this.getArticle();
      const { userId, userName, qatCrmId, articleId, kbSubject, articleContent,
              attachment
            } = article;

      if (attachment) {
        attachmentURL = await Storage.vault.get(attachment);
      }

      this.setState({
        article, userId, userName, qatCrmId, articleId, kbSubject, articleContent, attachmentURL
      });
    } catch ( err ) {
      // eslint-disable-next-line
      alert("This is the error: " + err);
    }
  }

  getArticle() {
    return API.get("knowledge-base", `/knowledge-base/${ this.props.match.params.qatCrmId }/${ this.props.match.params.articleId }`);
  }

  // Validate each of the form fields in the backend
  validateForm() {
    return (this.state.articleContent.length > 0 &&
      this.state.kbSubject.length > 0 &&
      this.state.articleContent.length > 0
    );
  }

  // format attachmentURL and strip the timestamp added to filename when uploaded
  formatFileName(str) {
    return str.replace(/^\w+-/, "");
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleFileChange = event => {
    this.file = event.target.files[0];
  }

  saveArticle(article) {
    return API.put("knowledge-base", `/knowledge-base/${this.props.match.params.qatCrmId}/${this.props.match.params.articleId}`, {
      body: article
    });
  }

  handleSubmit = async event => {
    let attachment;

    event.preventDefault();

    if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
      // eslint-disable-next-line
      alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE/1000000} MB.`);
      return;
    }

    this.setState({
      isLoading: true
    });

    try {
      if (this.file) {
        attachment = await s3Upload(this.file);
      }

      await this.saveArticle({
        userId: this.state.userId,
		qatCrmId: this.state.qatCrmId,
        userName: this.state.userName,
		articleId: this.state.articleId,
		kbSubject: this.state.kbSubject,
		articleContent: this.state.articleContent,
        attachment: attachment || this.state.article.attachment
      });

      this.props.history.push("/");
    } catch ( err ) {
      // eslint-disable-next-line
      alert(err);
      this.setState({
        isLoading: false
      });
    }
  }

  deleteArticle() {
    return API.del("knowledge-base", `/knowledge-base/${this.props.match.params.qatCrmId}/${this.props.match.params.articleId}`);
  }

  handleDelete = async event => {
    event.preventDefault();
    // eslint-disable-next-line
    const confirmed = window.confirm("Are you sure you want to delete this article?");

    if (!confirmed) {
      return;
    }

    this.setState({
      isDeleting: true
    });

    try {

      await this.deleteArticle();
      this.props.history.push("/");

    } catch ( err ) {
      // eslint-disable-next-line
      alert(err);
      this.setState({
        isDeleting: false
      });
    }
  }

  getIdNum(str) {
  	let regex = /(\w+)[^-]*$/g;
  	let match = regex.exec(str)[0];

  	console.log("Here is the articleId: ", match);
  	return match.toString();
  }

  render(){
	return (
      <article>
        <Media query="(min-width: 319px) and (max-width: 567px)">
          <div className="article-chrome">
            <p className="existing-article-title-sm">
              <strong>Update Knowledgebase Article</strong>
            </p>

            <Container className="article-record" fluid="True">
              { this.state.article &&
					<Form className="article-form" onSubmit={ this.handleSubmit }>

						<Form.Group as={ Row } className="article-num-row" controlId="articleId">
							<Form.Label className="article-num-label">
								Article Num:
							</Form.Label>

							<Form.Control plaintext
								readOnly
								className="article-num-field"
								value={ this.getIdNum(this.state.articleId) } />
						</Form.Group>

						<Form.Group className="article-subject-group" controlId="kbSubject">
							<Form.Label>
								Article Subject
							</Form.Label>
							<Form.Control as="input"
								className="case-fields"
								size="lg"
								value={ this.state.kbSubject }
								onChange={ this.handleChange } />
							<Form.Text className="text-muted">
								Please enter a title for this article.
							</Form.Text>
						</Form.Group>

						<Form.Group className="article-content-group" controlId="articleContent">
							<Form.Label>
								Article Content
							</Form.Label>
							<Form.Control as="textarea"
								rows="10"
								className="article-content"
								size="lg"
								value={ this.state.articleContent }
								onChange={ this.handleChange } />
							<Form.Text className="text-muted">
								Please enter the content of the article you want to create.
							</Form.Text>
						</Form.Group>

						<Form.Group className="kb-attachment-group" controlId="file">
							<Form.Label>
								Attachment
							</Form.Label>
							<Form.Control type="file" onChange={ this.handleFileChange } />
							<Form.Text className="text-muted">
								Please include any attachments as needed.
							</Form.Text>
						</Form.Group>

						<Form.Group className="article-btn-group">
							<LoaderButton block
								size="lg"
								disabled={ !this.validateForm() }
								variant="primary"
								type="submit"
								isLoading={ this.state.isLoading }
								text="Save"
								loadingText="Saving..."
							/>
							{ /* Add a delete button here */ }
							<LoaderButton block
								size="lg"
								disabled={ !this.validateForm() }
								variant="danger"
								isLoading={ this.state.isDeleting }
								onClick={ this.handleDelete }
								text="Delete"
								loadingText="Deleting..."
							/>
						</Form.Group>
                </Form>
              }
            </Container>
          </div>
        </Media>

		{/* TODO: Smaller Landscapes and up */}
        <Media query="(min-width: 568px)">
			<div className="article-chrome">
				<p className="existing-article-title">
					<strong>Update KnowledgeBase Article</strong>
				</p>

				<Container className="article-record" fluid="True">
                { this.state.article &&

					<Form className="article-form" onSubmit={ this.handleSubmit }>

						<Form.Group as={ Row } className="article-num-row" controlId="articleId">
							<Form.Label className="article-num-label">
								Article Num:
							</Form.Label>

							<Form.Control plaintext
								readOnly
								className="article-num-field"
								value={ this.getIdNum(this.state.articleId) } />
						</Form.Group>

						<Form.Group className="article-subject-group" controlId="kbSubject">
							<Form.Label>
								Article Subject
							</Form.Label>
							<Form.Control as="input"
								className="case-fields"
								size="lg"
								value={ this.state.kbSubject }
								onChange={ this.handleChange } />
							<Form.Text className="text-muted">
								Please enter a title for this article.
							</Form.Text>
						</Form.Group>

						<Form.Group className="article-content-group" controlId="articleContent">
							<Form.Label>
								Article Content
							</Form.Label>
							<Form.Control as="textarea"
								rows="10"
								className="article-content"
								size="lg"
								value={ this.state.articleContent }
								onChange={ this.handleChange } />
							<Form.Text className="text-muted">
								Please enter the content of the article you want to create.
							</Form.Text>
						</Form.Group>

						<Form.Group className="article-attachment-group" controlId="file">
							<Form.Label>
								Attachment
							</Form.Label>
							<Form.Control type="file" onChange={ this.handleFileChange } />
							<Form.Text className="text-muted">
								Please include any attachments as needed.
							</Form.Text>
						</Form.Group>

						<Form.Group className="article-btn-group">
							<LoaderButton block
								size="lg"
								disabled={ !this.validateForm() }
								variant="primary"
								type="submit"
								isLoading={ this.state.isLoading }
								text="Save"
								loadingText="Saving..."
							/>
							{ /* Add a delete button here */ }
							<LoaderButton block
								size="lg"
								disabled={ !this.validateForm() }
								variant="danger"
								isLoading={ this.state.isDeleting }
								onClick={ this.handleDelete }
								text="Delete"
								loadingText="Deleting..."
							/>
						</Form.Group>
					</Form>
				}
				</Container>
            </div>
		</Media>
      </article>
        );
  }
}
