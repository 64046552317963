import React, { Component } from "react";
// import Form from "react-bootstrap/Form";
// import FormControl from 'react-bootstrap/FormControl';

// HelpBlock
// FormGroup
// ControlLabel

// import { Auth } from "aws-amplify";
// import LoaderButton from "../components/LoaderButton";
import UserRegistration from "../components/UserRegistration.js";
import "./Register.css";

// TODO: Remove all logic and implement in *Registration.js components
// TODO: Import each component as needed
// FIXME: Issue #34 https://github.com/lopezdp/QuickAutoTags/issues/34

export default class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      registrationStep: 1
    };
  }

  handleChange = event => {
    this.setState({
      // using controlId change the state of the value as needed
      [event.target.id]: event.target.value
    });
  }

  renderForm() {
    switch (this.state.registrationStep) {
      case 1:
        return (
          <div>
            <UserRegistration isAuthenticated={ this.props.isAuthenticated } userHasAuthenticated={ this.props.userHasAuthenticated } />
          </div>);
      default:
        break;
    }
  }

  // conditionally render the registration form or the confirmation form
  // depending on the user's registration state.
  render() {
    //console.log("THese are child props.isAuthenticated: " + this.props.isAuthenticated);
    //console.log("THese are child props.userHasAuthenticated: " + this.props.userHasAuthenticated);
    return (
      <div className="RegistrationChrome">
        { this.renderForm() }
      </div>
      );
  }
}


