import React, { Component/*, Fragment*/ } from "react";
import Media from "react-media";
import Form from "react-bootstrap/Form";
// import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
// Using react containers to achieve responsiveness in Medias
import Container from "react-bootstrap/Container";
import LoaderButton from "../components/LoaderButton";
import { API, Auth } from "aws-amplify";
import { s3Upload } from "../libs/awsLib";
import config from "../config";
import "./NewKnowledgeBaseArticle.css";

export default class NewKnowledgeBaseArticle extends Component {
  constructor(props) {
    super(props);
    this.file = null;

    /*
     * The following data will need to be inserted:
		  userId: event.requestContext.identity.cognitoIdentityId,
	      articleId: uuid.v1(),
	      subject: data.subject,
	      articleContent: data.articleContent,
	      category: data.category,
	      keywords: data.keywords,
	      attachment: data.attachment,
	      createdAt: Date.now()
     */

    this.state = {
      isLoading: null,
      qatCrmId: "",
      userName: "",
      articleId: "",
      kbSubject: "",
      articleContent: "",
      //category: "",
      //keywords: "",
      createdAt: new Date(Date.now())
    };
  }

  // TODO: must be async call to get last num to handle caseNum logic
  componentDidMount() {
	//NOTE: Get custom:qatCrmId (and others) from cognito stored attributes
	this.getCurrUserData()
		.then((user) => {
			this.setState({
				qatCrmId: user.attributes["custom:qatCrmId"],
				userName: user.attributes["custom:qatUserName"]
			});
		})
		.catch((err) => {
			console.log(err);
		});
	console.log(this.state.qatCrmId);

    this.setState({
      articleId: "00001",
      isLoading: false
    });
  }

  // TODO: NEED TO GET AcctId to map to user permissions etc
  // MAYBE DEPRECATE
  getPrimaryCrmAccountId() {
	let accountId;
	// TODO: logic here

	return accountId;
  }

  // TODO: NEED TO GET USER DATA to MAP to permissions etc
  // eslint-disable-next-line
  getCurrUserData = async () => {	
    this.setState({
      isLoading: true
    });
	//let userId;
	//let userName;
	// TODO: logic here
	try {
		return await Auth.currentAuthenticatedUser({
			bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
		// eslint-disable-next-line
		});
	} catch ( err ) {
      // This comment disables eslint to allow the alert to go thru
      // eslint-disable-next-line
      alert(err);
      this.setState({
        isLoading: false
      });
	}
  }

  handleChange = event => {
    //this.getLastNumber();
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  validateForm() {
    return (this.state.kbSubject.length > 0 && this.state.articleContent.length > 0);
  }

  handleSubmit = async event => {
    event.preventDefault();

    const max = config.MAX_ATTACHMENT_SIZE / 1000000;

    if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
      // This comment disables eslint to allow the alert to go thru
      // eslint-disable-next-line
      alert(`Please pick a file smaller than ${ max } MB.`);
      return;
    }

    this.setState({
      isLoading: true
    });

    // TODO: Connect to API and persist new records to DB
    // TODO: Call the createCaseRecord() API here to store
    //       new case records in the db.
    // ss[.]c/c/call-the-create-api.html
    // upload-a-file-tos3.html

    try {
      const attachment = this.file
        ? await s3Upload(this.file)
        : null;

      await this.createNewArticle({
        attachment,
		qatCrmId: this.state.qatCrmId,
        userName: this.state.userName,
		articleId: this.state.articleId,
		kbSubject: this.state.kbSubject,
		articleContent: this.state.articleContent,
		createdAt: this.state.createdAt
      });

      this.props.history.push("/");
    } catch ( err ) {
      // This comment disables eslint to allow the alert to go thru
      // eslint-disable-next-line
      alert(err);
      this.setState({
        isLoading: false
      });
    }
  }

  createNewArticle(article) {
    return API.post("knowledge-base", "/knowledge-base", {
      body: article
    });
  }

  render() {

    return (
      <Container fluid="True">
		<Media query="(min-width: 319px) and (max-width: 567px)">
			<div id="kb-div">
				<p className="kb-title-sm">
					<strong>Knowledge Base</strong>
				</p>

				<Container className="New_KnowledgeBase_Article-sm" fluid="True">

					<Form className="article" onSubmit={ this.handleSubmit }>

						<Form.Group as={ Row } className="article-num-row-sm" controlId="articleId">
							<Form.Label className="article-num-label-sm">
								Article Num:
							</Form.Label>

							<Form.Control plaintext
								readOnly
								className="article-num-field"
								value={ this.state.articleId } />
						</Form.Group>

						<Form.Group className="subject-group" controlId="kbSubject">
							<Form.Label>
								Knowledgebase Article Subject
							</Form.Label>
							<Form.Control as="input"
								className="case-fields"
								size="lg"
								value={ this.state.kbSubject }
								onChange={ this.handleChange } />
							<Form.Text className="text-muted">
								Please enter a title for this article.
							</Form.Text>
						</Form.Group>

						<Form.Group className="content-group" controlId="articleContent">
							<Form.Label>
								Article Content
							</Form.Label>
							<Form.Control as="textarea"
								rows="10"
								className="article-content"
								size="lg"
								value={ this.state.articleContent }
								onChange={ this.handleChange } />
							<Form.Text className="text-muted">
								Please enter the content of the article you want to create.
							</Form.Text>
						</Form.Group>

						<Form.Group className="attachment-group" controlId="file">
							<Form.Label>
								Attachment
							</Form.Label>
							<Form.Control type="file" onChange={ this.handleFileChange } />
							<Form.Text className="text-muted">
								Please include any attachments as needed.
							</Form.Text>
						</Form.Group>

						<Form.Group className="loader-btn-group">
							<LoaderButton block
								size="lg"
								disabled={ !this.validateForm() }
								variant="primary"
								type="submit"
								isLoading={ this.state.isLoading }
								text="Create"
								loadingText="Creating..." />
						</Form.Group>
					</Form>
				</Container>
            </div>
		</Media>

		{/* TODO: Smaller Landscapes and up */}
        <Media query="(min-width: 568px)">
			<div className="kb-chrome">
				<p className="kb-title">
					<strong>Knowledge Base</strong>
				</p>

				<Container className="New_KnowledgeBase_Article" fluid="True">

					<Form className="article" onSubmit={ this.handleSubmit }>

						<Form.Group as={ Row } className="article-num-row-sm" controlId="articleId">
							<Form.Label className="article-num-label-sm">
								Article Num:
							</Form.Label>

							<Form.Control plaintext
								readOnly
								className="article-num-field"
								value={ this.state.articleId } />
						</Form.Group>

						<Form.Group className="subject-group" controlId="kbSubject">
							<Form.Label>
								Article Subject
							</Form.Label>
							<Form.Control as="input"
								className="case-fields"
								size="lg"
								value={ this.state.kbSubject }
								onChange={ this.handleChange } />
							<Form.Text className="text-muted">
								Please enter a title for this article.
							</Form.Text>
						</Form.Group>

						<Form.Group className="content-group" controlId="articleContent">
							<Form.Label>
								Article Content
							</Form.Label>
							<Form.Control as="textarea"
								rows="10"
								className="article-content"
								size="lg"
								value={ this.state.articleContent }
								onChange={ this.handleChange } />
							<Form.Text className="text-muted">
								Please enter the content of the article you want to create.
							</Form.Text>
						</Form.Group>

						<Form.Group className="attachment-group" controlId="file">
							<Form.Label>
								Attachment
							</Form.Label>
							<Form.Control type="file" onChange={ this.handleFileChange } />
							<Form.Text className="text-muted">
								Please include any attachments as needed.
							</Form.Text>
						</Form.Group>

						<Form.Group className="loader-btn-group">
							<LoaderButton block
								size="lg"
								disabled={ !this.validateForm() }
								variant="primary"
								type="submit"
								isLoading={ this.state.isLoading }
								text="Create"
								loadingText="Creating..." />
						</Form.Group>
					</Form>
				</Container>
            </div>
			</Media>


	        {/* Bigger Landscapes */}
	        <Media query="(min-width: 640px) and (max-width: 767px)">
				<p>KnowledgeBase Test 640 - 767</p>
	        </Media>




	        {/* iPads */}
	        <Media query="(min-width: 768px) and (max-width: 991px)">
				<p>KnowledgeBase Test 768 - 991</p>
	        </Media>



	        {/* Desktops */}
	        <Media query="(min-width: 992px)">
				<p>KnowledgeBase Test 992</p>
	        </Media>
      </Container>
	);
  }
}









