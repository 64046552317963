import React, { Component } from "react";
import { API } from "aws-amplify";
import DashboardUiCard from "../components/DashboardUiCard";
import "./Home.css";
import HomeLandingPage from "../components/HomeLandingPage";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      cases: [],
      articles: [],
      users: [],
      transactions: []

    };
  }

  async componentDidMount() {
    // eslint-disable-next-line
    if (!this.props.isAuthenticated) return;

    // NOTE: This tries to get cases for dashboard
    try {
      const cases = await this.cases();
      //const users = await this.users();
      //const transactions = await this.transactions();

      this.setState({
        cases
      });

      } catch ( err ) {
      // eslint-disable-next-line
      alert("ERROR: Home.js  componentDidMount cases problem: " + err);
    }

    // NOTE: This tries to get articles for dashboard
    try {
      const articles = await this.articles();
      //const users = await this.users();
      //const transactions = await this.transactions();

      this.setState({
        articles
      });

      } catch ( err ) {
      // eslint-disable-next-line
      alert("ERROR: Home.js  componentDidMount article problem: " + err);
    }

    // NOTE: This tries to get users for dashboard
    try {
      const users = await this.users();
      //const users = await this.users();
      //const transactions = await this.transactions();

      this.setState({
        users
      });

      } catch ( err ) {
      // eslint-disable-next-line
      alert("ERROR: Home.js  componentDidMount users problem: " + err);
    }

    console.log("These are the users: ", this.state.users);

    this.setState({
      isLoading: false
    });
  }

  cases() {
    return API.get("case-records", "/case-records");
  }

  articles() {
    return API.get("knowledge-base", "/knowledge-base");
  }

  users() {
    return API.get("users", "/users");
  }
  // FIXME: This is not the official endpoint yet
  //        just a placeholder for now
  transactions() {
    return API.get("transactions", "/transactions");
  }

  renderLandingPage() {
    return (
      <div className="LandingPage">
        <HomeLandingPage />

      </div>
      );
  }

  // refactored to dash
  // TODO: Need to redo and loop through every case in db to list properly
  renderDashboard() {
    return (
      <article className="Dashboard">
        <DashboardUiCard
          articles={ this.state.articles }
          cases={ this.state.cases }
          users={ this.state.users }
          transactions={ this.state.articles }
        />
      </article>
      );
  }

  // TODO: NEED TO BUILD OUT DASHBOARD COMPONENT HERE
  //       AND CALL BASED ON AUTH STATUS

  render() {
    return (
      <main className="Home">
        { this.props.isAuthenticated ? this.renderDashboard() : this.renderLandingPage() }
      </main>
      );
  }
}
