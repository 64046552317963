import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Media from "react-media";
import "./HomeLandingPage.css";
import ParallaxMenu from "./ParallaxMenu";
import HorizonMenu from "./HorizonMenu";
import Footer from "./Footer";
import LoaderButton from "./LoaderButton";
import AvrsModal from "../containers/AvrsModal";

export default class HomeLandingPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			modalShow: true,
		};
	}

	setModalShow = (show) => {
		this.setState({
			modalShow: show,
		});
	};

	render() {
		return (
			<div>
				<Carousel>
					<Carousel.Item className="home-carousel">
						<Image
							className="d-block w-100 dmv-img"
							src="/img/LandingPageImages/qatLanderImageFun1.jpg"
							alt="First slide"
							fluid
						/>

						<Media
							query="(min-width: 250px)"
							render={() => (
								<Container className="btn-container">
									<LoaderButton
										block
										size="lg"
										className="modal-btn-sm-portrait"
										disabled={this.state.isLoading}
										variant="danger"
										type="button"
										onClick={this.setModalShow.bind(
											this,
											true
										)}
										isLoading={this.state.isLoading}
										text="Renew Today"
										loadingText="Creating..."
									/>
								</Container>
							)}
						/>

						<Carousel.Caption>
							<h3 className="carousel-title-1">
								Empowering your Journey
							</h3>
							<p className="carousel-p-1">
								Reach your destination with everyone you love.
							</p>
						</Carousel.Caption>
					</Carousel.Item>

					<Carousel.Item className="home-carousel">
						<Image
							className="d-block w-100 dmv-img"
							src="/img/LandingPageImages/qatLanderImageRoad2.jpg"
							alt="Third slide"
						/>

						<Media
							query="(min-width: 250px)"
							render={() => (
								<Container className="btn-container">
									<LoaderButton
										block
										size="lg"
										className="modal-btn-sm-portrait"
										disabled={this.state.isLoading}
										variant="danger"
										type="button"
										onClick={this.setModalShow.bind(
											this,
											true
										)}
										isLoading={this.state.isLoading}
										text="Renew Today"
										loadingText="Creating..."
									/>
								</Container>
							)}
						/>

						<Carousel.Caption>
							<h3 className="carousel-title-1">
								Embrace the Open Road
							</h3>
							<p className="carousel-p-1">
								Your DMV on your own time.
							</p>
							<p className="carousel-p-1">
								Take to the road knowing that your vehicle
								registration is valid with the CA DMV.
							</p>
						</Carousel.Caption>
					</Carousel.Item>

					<Carousel.Item className="home-carousel">
						<Image
							className="d-block w-100 dmv-img"
							src="/img/LandingPageImages/qatLanderImageDL3.jpg"
							alt="Third slide"
						/>

						<Media
							query="(min-width: 250px)"
							render={() => (
								<Container className="btn-container">
									<LoaderButton
										block
										size="lg"
										className="modal-btn-sm-portrait"
										disabled={this.state.isLoading}
										variant="danger"
										type="button"
										onClick={this.setModalShow.bind(
											this,
											true
										)}
										isLoading={this.state.isLoading}
										text="Renew Today"
										loadingText="Creating..."
									/>
								</Container>
							)}
						/>

						<Carousel.Caption>
							<h3 className="carousel-title-1">
								Register your Vehicle Today
							</h3>
							<p className="carousel-p-1">
								Avoid the DMV and register your vehicle
								registration online, and skip the line!
							</p>
						</Carousel.Caption>
					</Carousel.Item>
				</Carousel>

				<header className="solution-header">
					<h1>Quick Auto Tags</h1>
					<p>Easy DMV CA Gov Vehicle Registration Services.</p>
				</header>

				<Container className="solution-container" fluid="True">
					<article className="service-container">
						<Link
							to="/register"
							className="service-block"
							onClick={void 0}
							onTouchStart={() => ""}
						>
							<section
								className="service-button"
								onClick={void 0}
								onTouchStart={() => ""}
							>
								<div className="service-title">Dealers</div>
								<div>
									<Image
										src="../img/ServiceGroups/DealerIcon.png"
										className="service-icon"
									/>
								</div>
							</section>
						</Link>

						<p className="service-tagline">
							Track your Title Transfers and Renew your
							Registrations easily.
						</p>

						<Link
							to="/register"
							className="lander-button"
							onClick={void 0}
							onTouchStart={() => ""}
						>
							See our List of Dealers
						</Link>
					</article>

					<article className="service-container">
						<Link
							to="/register"
							className="service-block"
							onClick={void 0}
							onTouchStart={() => ""}
						>
							<section
								className="service-button"
								onClick={void 0}
								onTouchStart={() => ""}
							>
								<div className="service-title">Fleets</div>
								<div>
									<Image
										src="../img/ServiceGroups/FleetsIcon.png"
										className="service-icon"
									/>
								</div>
							</section>
						</Link>

						<p className="service-tagline">
							Perform Batch Renewals on your Fleets and Save Time!
						</p>

						<Link
							to="/register"
							className="lander-button"
							onClick={void 0}
							onTouchStart={() => ""}
						>
							See our List of Fleets
						</Link>
					</article>

					<article className="service-container">
						<Link
							to="/register"
							className="service-block"
							onClick={void 0}
							onTouchStart={() => ""}
						>
							<section
								className="service-button"
								onClick={void 0}
								onTouchStart={() => ""}
							>
								<div className="service-title">
									Transport Companies
								</div>
								<div>
									<Image
										src="../img/ServiceGroups/TransportIcon.png"
										className="service-icon"
									/>
								</div>
							</section>
						</Link>

						<p className="service-tagline">
							Track your Titles your Drivers and your Renewals.
						</p>

						<Link
							to="/register"
							className="lander-button"
							onClick={void 0}
							onTouchStart={() => ""}
						>
							See our List of Transports
						</Link>
					</article>
				</Container>
				<div className="parallax-menu">
					{/* Need the Parallax Menu to display new features */}
					<ParallaxMenu />
				</div>

				{/* Need the Horizontal Menu to display new features */}
				<HorizonMenu />

				{/* Need the Footer Menu to display new features */}
				<Footer />

				<AvrsModal
					show={this.state.modalShow}
					setModalShow={this.setModalShow}
					isAuthenticated={this.props.isAuthenticated}
					userHasAuthenticated={this.props.userHasAuthenticated}
				/>
			</div>
		);
	}
}
