import React from "react";
import { Switch, Route } from "react-router";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import Register from "./containers/Register";
import NewCaseRecord from "./containers/NewCaseRecord";
import NewKnowledgeBaseArticle from "./containers/NewKnowledgeBaseArticle";
import CaseRecord from "./containers/CaseRecord";
import KbArticle from "./containers/KbArticle";
import UserRecord from "./containers/UserRecord";
import AppliedRoute from "./components/AppliedRoute";
import TermsAndConditions from "./components/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Fees from "./components/Fees";
import License from "./components/License";
import Support from "./components/Support";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

export default ({childProps}) => <Switch>
                                   { /* This is our home page route for the main landing page to the app */ }
                                   <AppliedRoute path="/"
                                     exact
                                     component={ Home }
                                     props={ childProps } />

                                   { /* This is the Login Route */ }
                                   <UnauthenticatedRoute path="/"
                                     exact
                                     component={ Home }
                                     props={ childProps } />

                                   { /* This is the Login Route */ }
                                   <UnauthenticatedRoute path="/login"
                                     exact
                                     component={ Login }
                                     props={ childProps } />

                                   { /* This is the Registration Route */ }
                                   <UnauthenticatedRoute path="/register"
                                     exact
                                     component={ Register }
                                     props={ childProps } />

                                   { /* This is the terms-conditions Route */ }
                                   <UnauthenticatedRoute path="/terms-conditions"
                                     exact
                                     component={ TermsAndConditions }
                                     props={ childProps } />

                                   { /* This is the privacy-policy Route */ }
                                   <UnauthenticatedRoute path="/privacy-policy"
                                     exact
                                     component={ PrivacyPolicy }
                                     props={ childProps } />

                                   { /* This is the fees Route */ }
                                   <UnauthenticatedRoute path="/fees"
                                     exact
                                     component={ Fees }
                                     props={ childProps } />

                                   { /* This is the ca-dmv-bpa-license Route */ }
                                   <UnauthenticatedRoute path="/ca-dmv-bpa-license"
                                     exact
                                     component={ License }
                                     props={ childProps } />

                                   { /* This is the support Route */ }
                                   <UnauthenticatedRoute path="/support"
                                     exact
                                     component={ Support }
                                     props={ childProps } />

                                   { /* This is the NewCaseRecord Route */ }
                                   <AuthenticatedRoute path="/case-records/new"
                                     exact
                                     component={ NewCaseRecord }
                                     props={ childProps } />

                                   { /* This is the route used to display unique CaseRecords */ }
                                   <AuthenticatedRoute path="/case-records/:qatCrmId/:caseRecordId"
                                     exact
                                     component={ CaseRecord }
                                     props={ childProps } />

                                   { /* This is the route used to display unique KnowledgeBaseArticles */ }
                                   <AuthenticatedRoute path="/knowledge-base/new"
                                     exact
                                     component={ NewKnowledgeBaseArticle }
                                     props={ childProps } />

                                   { /* This is the route used to display unique KnowledgeBaseArticles */ }
                                   <AuthenticatedRoute path="/knowledge-base/:qatCrmId/:articleId"
                                     exact
                                     component={ KbArticle }
                                     props={ childProps } />

                                   { /* This is the route used to display unique KnowledgeBaseArticles */ }
                                   <AuthenticatedRoute path="/users/:qatCrmId/:userName"
                                     exact
                                     component={ UserRecord }
                                     props={ childProps } />

                                   { /* This route will catch all unmatched routes && MUST BE LAST!!! */ }
                                   <Route component={ NotFound } />
                                 </Switch>;
