/* NOTE: Comment below needed for Google API. Do not remove! */
/* Used to call google.maps API in GeoSuggest!!! */

/* global google */

import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import ProgressBar from "react-bootstrap/ProgressBar";
import Media from "react-media";
// NOTE: This import used for feedback in bootstrap4

// HelpBlock
// FormGroup
// ControlLabel

import { API, Auth } from "aws-amplify";
import Geosuggest from "react-geosuggest";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import RegistrationProgressAside from "./RegistrationProgressAside";
import UserConfirmation from "./UserConfirmation";
import "./UserRegistration.css";

export default class UserRegistration extends Component {
  constructor(props) {
    super(props);

    /*
     * The following data will need to be inserted:
     * - Customer name
     * - Date ticket was created (automatically generated)
     * - Time ticket was created (automatically generated)
     * - Have a drop down menu with a “level of urgency”
     * - VIN Number
     * - Plate number
     * - Year make and model
     * - Case description
     * - Related transaction number
     * - button to initiate AVRS txn?
     *
     */

    /* qatCrmId
     *
     * Use this as the dynamo db sort key
     * make as composite key with:
     *
     * corpName
     * zipCode
     *
     *
     *
     */

    this.state = {
      isLoading: false,
      isExisting: true,
      geoCodeURL: "",
      orgType: "",
      corpName: "",
      govNumber: "",
      qatCrmId: "",
      userName: "",
      approvalCode: "",
      streetAddress: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
      firstName: "",
      lastName: "",
      title: "",
      officePhone: "",
      mobilePhone: "",
      email: "",
      password: "",
      confirmPassword: "",
      confirmationCode: "",
      validated: false,
      registrationStep: 0,
      newUser: null,
      userRole: ""
    };

    // NOTE: Bind the registration workflow and the suggestion selector
    this.registrationWorkFlow = this.registrationWorkFlow.bind(this);
    this.selectSuggestion = this.selectSuggestion.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  //NOTE: When compone  nt mounts this takes over
  componentDidMount() {
    if (this.state.registrationStep.toString() === "0") {
      this.setState({
        registrationStep: 1
      });
    }
  }

  // FIXME: may need to delete or merge this into more
  //        efficient validation schemes
  // Need to make sure the form is filled in with information
  validateForm() {
    let b = false;
    // eslint-disable-next-line
    //console.log("validateForm called!!!!");

    // If on step 1 && dealer has name && name is NaN && valid address
    // then bool is true
    if (this.state.registrationStep.toString() === "1") {
      if (this.state.corpName.length > 0 &&
        isNaN(this.state.corpName) &&
        this.state.streetAddress) {

        b = true;
      }
    }

    //validate fields in step 2 before submitting form
    if (this.state.registrationStep.toString() === "2") {
      if (this.state.firstName.length > 0 && isNaN(this.state.firstName) &&
        this.state.lastName.length > 0 && isNaN(this.state.lastName) &&
        this.state.title.length > 0 && isNaN(this.state.title) &&
        this.state.officePhone.length === 10 && !isNaN(this.state.officePhone) &&
        this.state.mobilePhone.length === 10 && !isNaN(this.state.mobilePhone)) {
        b = true;
      }
    }

    //validate fields in step 3 before submitting form
    if (this.state.registrationStep.toString() === "3") {
      if (this.state.qatCrmId.length > 8 &&
        isNaN(this.state.qatCrmId) &&
        this.state.userName.length > 0 &&
        this.state.email.length > 0 &&
        this.state.password.length > 0 &&
        this.state.password === this.state.confirmPassword) {
        // if false then confirmation is turned off!
        b = true;
      }
    }

    // return the bool value. true if form is valid or false if
    // the form is invalid...
    return (b);
  }

  handleChange = event => {
    this.setState({
      // using controlId change the state of the value as needed
      [event.target.id]: event.target.value
    });
  }

  findQatCrmId() {}

  // eslint-disable-next-line
  regexAddress(suggestion) {
    if (suggestion.length > 0) {
      // FIXME: When address is 1085-1087 Boylston St Regex fails at 1085-
      let result = suggestion.split(",")[0];
      return result;
    }
  }

  // eslint-disable-next-line
  zipCodeSearch = async event => {

    let formattedAddress;
    let url = this.state.geoCodeURL;

    // NOTE: FIXED THE ERROR WITH PARSING THE JSON RESPONSE
    // Need to get address data back from google
    // to pull zipcode data
    // eslint-disable-next-line
    await fetch(url)
      .then(response => response.json())
      // eslint-disable-next-line
      .then(data => formattedAddress = data.results[0].formatted_address.split(", "));

    let city = formattedAddress[formattedAddress.length - 3];
    let state = formattedAddress[formattedAddress.length - 2].split(" ")[0];
    let zip = formattedAddress[formattedAddress.length - 2].split(" ")[1];
    let country = formattedAddress[formattedAddress.length - 1];
    let qatCrmId = (this.state.corpName + zip).replace(/ /g, "");

    this.setState({
      city: city,
      state: state,
      zipCode: zip,
      country: country,
      qatCrmId: qatCrmId
    });

  // 711 East Okeechobee Road, Hialeah, FL 33010, USA
  }

  selectSuggestion(suggestion) {
    try {
      this.setState({
        streetAddress: suggestion.label
      });

      let apiKey = config.Places_API_KEY;
      let result = this.regexAddress(this.state.streetAddress);

      this.setState({
        streetAddress: result
      });

      let url = "https://maps.googleapis.com/maps/api/geocode/json?address=";

      let address = this.state.streetAddress.split(" ");

      let query = "";

      for (let i = 0; i < address.length; i++) {
        if (i === (address.length - 1)) {
          query = query + address[i];
        } else {
          query = query + address[i] + "+";
        }
      }

      // build the google api url we need to get location data
      url += query + "&key=" + apiKey;

      if (url.length > 0) {
        this.setState({
          geoCodeURL: url
        });
      }

      // NOTE: call the zipCodeSearch function so that
      //       we can extract location data from google
      //       response inside of formatted_address field
      this.zipCodeSearch();

    } catch ( err ) {
      this.setState({
        streetAddress: ""
      });
    }
  }

  handleSubmit = async event => {

    const form = event.currentTarget;

    // eslint-disable-next-line
    console.log("This is the checkValidity res: " + form.checkValidity());

    // NOTE: This will check the validity of the input
    //       fields with html5 validation tools
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.setState({
      validated: true
    });

    event.preventDefault();

    this.setState({
      // set button spinner state to loading
      // while we wait for async responses
      isLoading: true
    });

    try {
      // call cognito with amplify api
      const newUser = await Auth.signUp({
        username: this.state.email.toLowerCase(),
        password: this.state.password,
        attributes: {
          "custom:userRole": this.state.userRole, // custom attribute, not standard
          "custom:qatCrmId": this.state.qatCrmId, // custom attribute, not standard
          "custom:qatUserName": this.state.userName // custom attribute, not standard
        }
      });

      this.setState({
        // set state with newUser data
        newUser
      });
    } catch ( err ) {
      // eslint-disable-next-line
      alert("ERROR in UserReg handleSubmit: " + err.message);
    }

    this.setState({
      // turn off loading when async complete
      isLoading: false
    });
  }

  renderProgressBar = (now) => {
    const progressBar = <ProgressBar animated
                          variant="info"
                          now={ now }
                          label={ `${now}%` } />;
    return (progressBar);
  }

  // TODO: Need functionality to handle state changes when button OnClick
  registrationWorkFlow() {
    this.setState({
      isLoading: true
    });

    if (this.state.registrationStep.toString() === "1") {
      this.setState({
        registrationStep: 2
      });
    }

    if (this.state.registrationStep.toString() === "2") {
      // FIXME: Confirm Logic
      this.setState({
        registrationStep: 3
      });
    }

    this.setState({
      isLoading: false
    });
  }

  /*
   *
   * @params
   * @return
   *
   * FIXME: Scan User DB to check for a qatCrmId
   *        If no Id found then setState to Admin
   *        else return user role to be approved
   */
  isExistingAccountId = async primaryAccountId => {
    try {
      const result = await API.get("users", `/users/validate/${ primaryAccountId }`);

      const { qatCrmId, attachment, orgType, corpName, govNumber, streetAddress,
        city, state, zipCode, country, firstName, lastName, title, officePhone, mobilePhone,
        userName, email, approvalCode, userRole } = result;

      // eslint-disable-next-line
      console.log("Here are the resultant values: \n" +
                  "============================== \n" +
                   "attachment\n" + attachment + " \n" +
                   "orgType\n" + orgType + " \n" +
                   "corpName\n" + corpName + " \n" +
                   "govNumber\n" + govNumber + " \n" +
                   "streetAddress\n" + streetAddress + " \n" +
                   "city\n" + city + " \n" +
                   "state\n" + state + " \n" +
                   "zipCode\n" + zipCode + " \n" +
                   "country\n" + country + " \n" +
                   "firstName\n" + firstName + " \n" +
                   "lastName\n" + lastName + " \n" +
                   "title\n" + title + " \n" +
                   "officePhone\n" + officePhone + " \n" +
                   "mobilePhone\n" + mobilePhone + " \n" +
                   "userName\n" + userName + " \n" +
                   "email\n" + email + " \n" +
                   "approvalCode\n" + approvalCode + " \n" +
                   "userRole\n" + userRole + " \n"
                  );

      if (qatCrmId === this.state.qatCrmId) {
        // eslint-disable-next-line
        console.log("This is the CHECK EXISTING ACCOUNT ID RESULT: " + qatCrmId);
        // eslint-disable-next-line
        console.log("This is the qatCrmId in state for the (newUser): " + this.state.qatCrmId);
        // return true if entry already exists

        return true;
      } else {
        // return false if no entry found that matches account id in state for new user
        return false;
      }
    } catch ( err ) {
      // eslint-disable-next-line
      console.log("Here is the ERROR: " + err.message);
      return false;
    }
    //TODO: Need to finish this and return something here
    //      so that if the response comes back with what
    //      we want we proceed with registration...
  }

  /*
   *
   * @params
   * @return
   *
   * FIXME: Complete the workflow that will
   *        let users "GO BACK" to previous
   *        input fields!!!
   */

  getPrevious() {}







  orgTypeMessage() {
    let msg = "";
    if (this.state.orgType === "Select From List") {
      msg = ". Please proceed to ";
    } else if (this.state.orgType === "Dealer" || this.state.orgType === "Fleet" || this.state.orgType === "Transport Co." || this.state.orgType === "ROV" || this.state.orgType === "Insurance Co." || this.state.orgType === "Franchise" || this.state.orgType === "Courier" || this.state.orgType === "Corporate") {
      msg = " about your";
    }
    return msg;
  }

  renderStep1() {
    let oType = " " + this.state.orgType;
    let entity = "";
    let registrationStep;
    let numType = "";
    let pHolder1 = "";
    let pHolder2 = "";
    let clsName01 = "";
    let clsName02 = "";
    let govNumMsg = "";
    let msg2 = "";

    const {validated} = this.state.validated;

    if (this.state.registrationStep) {
      registrationStep = this.state.registrationStep.toString();
    }

    if (oType === "Select From List" || oType === " Select From List") {
      oType = "";
      clsName01 = "default-num-style";
    }

    if (oType === " ROV") {
      entity = " Personal";
    } else if (oType === " Corporate") {
      entity = " Employee";
    } else {
      entity = " Corporate";
    }

    if (oType === " Dealer") {
      // If a Dealer, thenKeep numType == oType
      // we just need to ask for DealerNumber
      numType = " Dealer";
      pHolder1 = "Ex: Westland Auto Dealers, Inc.";
      pHolder2 = "Ex: DMV Occupational License Number";
    } else if (oType === " Fleet") {
      // If a Fleet, we need PFR
      numType = " PFR";
      pHolder1 = "Ex: California Fleet Services, LLC.";
      pHolder2 = "Ex: Permanent Fleet Registration Number";
    } else if (oType === " Corporate") {
      // If a Corporate (Employee), we need SSN
      numType = " SSN";
      msg2 = "with ";
      pHolder1 = "Ex: Your Employee Contract Number: QAT1234";
      pHolder2 = "Ex: 123-45-6789";
      govNumMsg = " against all appropriate work authorization records as required by law";

    } else if (oType === " " || oType === " Transport Co." || oType === " ROV" || oType === " Insurance Co." ||
      oType === " Franchise" || oType === " Courier" || oType === " Corporate") {

      // If a Fleet, we need PFR
      //Franchise|Courier|Corporate
      clsName01 = "default-num-style";
    }

    if (oType === " ROV" || oType === " Corporate") {
      clsName01 = "default-help-style";
    }

    //against the California DMV Occupational License Records
    if (oType === " Dealer" || oType === " Fleet") {
      govNumMsg = " against the California DMV Occupational License Records";
    }

    let msg = this.orgTypeMessage();

    return (
      <div>
        <header className="hdr1">
          <h1 className="title">Register your Account</h1>
          <h3 className="tagline">Step 1: Basic Information{ `${ msg } ${ this.state.orgType }` }</h3>
        </header>
        <header className="hdr2">
          <h2 className="title">Register your Account</h2>
          <h4 className="tagline">Step 1: Basic Information{ `${ msg } ${ this.state.orgType }` }</h4>
        </header>
        <header className="hdr4">
          <h4 className="title">Register your Account</h4>
          <h6 className="tagline">Step 1: Basic Info{ `${ msg } ${ this.state.orgType }` }</h6>
        </header>
        <section id="progressBar">
          { this.renderProgressBar(5) }
        </section>
        <div className="signup">
          { /*Start Form for step 1 here!!*/ }
          <Form className="form"
            noValidate
            validated={ validated }
            onSubmit={ this.handleSubmit }>
            <p>
              <strong>Please Register { `${ msg2 }` } Your Organization</strong>
            </p>
            { /* This is the org type */ }
            <Form.Group controlId="orgType">
              <Form.Label>
                Organization Type
              </Form.Label>
              <Form.Control as="select"
                value={ this.state.orgType }
                onChange={ this.handleChange }
                required
                pattern="Dealer|Fleet|Transport Co.|ROV|Insurance Co.|Franchise|Courier|Corporate">
                <option>
                  Select From List
                </option>
                <option>
                  Dealer
                </option>
                <option>
                  Fleet
                </option>
                <option>
                  Transport Co.
                </option>
                <option>
                  ROV
                </option>
                <option>
                  Insurance Co.
                </option>
                <option>
                  Franchise
                </option>
                <option>
                  Courier
                </option>
                <option>
                  Corporate
                </option>
              </Form.Control>
            </Form.Group>
            { /* NOTE: This is the name of the corp to be validated */ }
            { /* TODO: Validate this name against db to make sure it is unique!! */ }
            <Form.Group controlId="corpName">
              <Form.Label>
                { `${ oType}` }
                { `${ entity }` } Name
              </Form.Label>
              <Form.Control type="text"
                value={ this.state.corpName }
                onChange={ this.handleChange }
                placeholder={ `${pHolder1}` }
                required
                minLength="8" />
              <Form.Text className={ `help ${clsName02}` }>
                Your corporation name will be validated against the State of California's Corporate Records.
              </Form.Text>
            </Form.Group>
            { /* This is Dealer Number (DMV Occupational License) that we verify */ }
            <Form.Group controlId="govNumber" className={ `${clsName01}` }>
              <Form.Label>
                { `${numType}` } Number
              </Form.Label>
              <Form.Control type="text"
                value={ this.state.govNumber }
                onChange={ this.handleChange }
                placeholder={ `${pHolder2}` }
                required
                minLength="5" />
              <Form.Text className="help">
                Your
                { `${entity}` } Number will be validated
                { `${govNumMsg}` }.
              </Form.Text>
            </Form.Group>
            { /* NOTE: Implemented Google Geocode API to confirm zipCode & city by location */ }
            <Form.Group controlId="streetAddress">
              <Form.Label>
                Street Address
              </Form.Label>
              <Geosuggest ref={ el => this._geoSuggest = el }
                placeholder="Enter you Street Address!"
                country="us"
                onSuggestSelect={ this.selectSuggestion }
                location={ new google.maps.LatLng(33.996575, -117.4068808) }
                radius="5000000"
                required/>
            </Form.Group>
            { /* This is street Addreess */ }
            <Form.Group controlId="addressLine2">
              <Form.Label>
                Suite or Unit
              </Form.Label>
              <Form.Control type="text"
                value={ this.state.addressLine2 }
                onChange={ this.handleChange }
                placeholder="Ex: Unit #101" />
              <Form.Text className="help">
                Please provide a Suite or Unit number if available.
              </Form.Text>
            </Form.Group>
            { /* LoaderButton Component */ }
            <LoaderButton block
              onClick={ this.registrationWorkFlow }
              size="lg"
              disabled={ !this.validateForm() }
              variant="primary"
              className="nxtBtn"
              isLoading={ this.state.isLoading }
              text="Save Changes"
              loadingText="Saving..." />
          </Form>
          { /* Section used for credibility seal on larger screens*/ }
          <section id="logo">
            TBD - Section here
          </section>
          { /* Aside used for credibility seal on mobile devices*/ }
          <RegistrationProgressAside orgType={ `${oType}` } registrationStep={ `${registrationStep}` } />
        </div>
      </div>
      );
  }

  renderStep2(oType) {
    let entity = "";
    let registrationStep;

    if (this.state.registrationStep) {
      registrationStep = this.state.registrationStep.toString();
    }

    if (oType === "Select From List" || oType === " Select From List") {
      oType = "";
    }

    if (oType === " ROV") {
      entity = " Personal";
    } else if (oType === " Admin") {
      entity = " Employee";
    } else {
      entity = " Corporate";
    }

    return (
      <div>
        <header className="hdr1">
          <h1 className="title">QAT CRM Registration</h1>
          <h3 className="tagline">Step 2: Create your first User Account</h3>
        </header>
        <header className="hdr2">
          <h2 className="title">QAT CRM Registration</h2>
          <h4 className="tagline">Step 2: Create your first User Account</h4>
        </header>
        <header className="hdr4">
          <h4 className="title">QAT CRM Registration</h4>
          <h6 className="tagline">Step 2: Create your first User Account</h6>
        </header>
        <section id="progressBar">
          { this.renderProgressBar(33) }
        </section>
        <div className="signup">
          { /*Start Form for step 1 here!!*/ }
          <Form className="form" onSubmit={ this.handleSubmit }>
            <p>
              <strong>Please Register As a New User</strong>
            </p>
            <Form.Text className="help">
              We will now add the first user for your
              { " " + this.state.orgType }. This user will have an <strong>Administrator</strong> role and will have full access to the platform so that you will be able to add additional users with
              modified privileges or to change system settings as needed for your business.
            </Form.Text>
            { /* This is the user's first name and middle initial */ }
            <Form.Group controlId="firstName">
              <Form.Label>
                First Name
              </Form.Label>
              <Form.Control type="text"
                value={ this.state.firstName }
                onChange={ this.handleChange }
                placeholder="Ex: Henry J." />
              <Form.Text className="help">
                Enter your first name and an optional middle initial.
              </Form.Text>
            </Form.Group>
            { /* This is the user's last name and middle initial */ }
            <Form.Group controlId="lastName">
              <Form.Label>
                Last Name
              </Form.Label>
              <Form.Control type="text"
                value={ this.state.lastName }
                onChange={ this.handleChange }
                placeholder="Ex: Jones" />
              <Form.Text className="help">
                Enter your first name and an optional middle initial.
              </Form.Text>
            </Form.Group>
            { /* This is the user's title */ }
            <Form.Group controlId="title">
              <Form.Label>
                Title
              </Form.Label>
              <Form.Control type="text"
                value={ this.state.title }
                onChange={ this.handleChange }
                placeholder="Ex: President, Director, or COO, etc" />
              <Form.Text className="help">
                Enter your corporate title. If this is the first time that you are registering your corporate account for your Fleet or Dealer, then you should be an executive of
                the corporation with the authority to make financial decisions, or the Owner of the company.
              </Form.Text>
            </Form.Group>
            { /* This is the user's office phone */ }
            <Form.Group controlId="officePhone">
              <Form.Label>
                Office Phone
              </Form.Label>
              <Form.Control type="tel"
                value={ this.state.officePhone }
                onChange={ this.handleChange }
                placeholder="Ex: (628) 425-2790" />
              <Form.Text className="help">
                Please <strong>ONLY</strong> include your 10-digit office phone number without any additional characters.
              </Form.Text>
            </Form.Group>
            { /* This is the user's mobile phone */ }
            <Form.Group controlId="mobilePhone">
              <Form.Label>
                Mobile Phone
              </Form.Label>
              <Form.Control type="tel"
                value={ this.state.mobilePhone }
                onChange={ this.handleChange }
                placeholder="Ex: (628) 425-2790" />
              <Form.Text className="help">
                Please <strong>ONLY</strong> include your 10-digit mobile phone number without any additional characters.
              </Form.Text>
            </Form.Group>
            { /* LoaderButton Component */ }
            <LoaderButton block
              onClick={ this.registrationWorkFlow }
              size="lg"
              disabled={ !this.validateForm() }
              variant="primary"
              className="nxtBtn"
              isLoading={ this.state.isLoading }
              text="Save Changes"
              loadingText="Saving..." />
          </Form>
          { /* Section used for credibility seal on larger screens*/ }
          <section id="logo">
            TBD - Section here
          </section>
          { /* Aside used for credibility seal on mobile devices*/ }
          <RegistrationProgressAside orgType={ `${oType}` } registrationStep={ `${registrationStep}` } />
        </div>
      </div>
      );
  }

  // NOTE: updating isExisting to false means that the
  //       primary account ID is unique and has not been used yet
  // FIXME: Not great
  updateExistingStateF = () => {
    this.setState({
      // using controlId change the state of the value as needed
       isExisting: false,
       userRole: "UserAdmin"
    });
  }

  updateExistingStateT = () => {
    this.setState({
      // using controlId change the state of the value as needed
       isExisting: true,
       userRole: "UserEmployee"
    });
  }

  renderStep3() {
    // eslint-disable-next-line
    let clsName;
    let registrationStep;
    let oType = " " + this.state.orgType;
    // eslint-disable-next-line
    let entity = "";

    if (this.state.registrationStep) {
      registrationStep = this.state.registrationStep.toString();
    }

    if (this.state.registrationStep.toString() === "3") {
      clsName = "listStep";
    } else {
      clsName = "list";
    }

    if (oType === "Select From List" || oType === " Select From List") {
      oType = "";
    }

    if (oType === " ROV") {
      entity = " Personal";
    } else if (oType === " Admin") {
      entity = " Employee";
    } else {
      entity = " Corporate";
    }
    // eslint-disable-next-line
    console.log(
      "orgType: " + this.state.orgType + " " +
      "corpName: " + this.state.corpName + " " +
      "govNumber: " + this.state.govNumber + " " +
      "qatCrmId: " + this.state.qatCrmId + " " +
      "streetAddress: " + this.state.streetAddress + " " +
      "addressLine2: " + this.state.addressLine2 + " " +
      "city: " + this.state.city + " " +
      "state: " + this.state.state + " " +
      "zipCode: " + this.state.zipCode + " " +
      "country: " + this.state.country + " " +
      "firstName: " + this.state.firstName + " " +
      "lastName: " + this.state.lastName + " " +
      "title: " + this.state.title + " " +
      "officePhone: " + this.state.officePhone + " " +
      "mobilePhone: " + this.state.mobilePhone + " " +
      "email: " + this.state.email + " " +
      "userName: " + this.state.userName + " " +
      "password: " + this.state.password + " " +
      "confirm: " + this.state.confirmPassword + " " +
      "approvalCode: " + this.state.approvalCode + " " +
      "registrationStep in state: " + this.state.registrationStep + " " +
      "registrationStep in var: " + registrationStep
    );

    // TODO: Need to call a function that will complete the following steps:
    //       1. scan the db and compare qatCrmId's
    //       2. if matching qatCrmId is found then notify the user that they have
    //          to register under the existing qatCrmId and then wait for approval
    //          from the owners of the account.
    //       3. Notify owner of account that a user tried to register under the account
    //       4. if user registers then send owner the form for approval so that the new
    //          user can be approved
    let isExisting = this.isExistingAccountId(this.state.qatCrmId);
    let existing;

    let promise = Promise.resolve(isExisting);

    promise.then((value) => {// expected output: bool

      if (value.toString() === "false"){
        existing = "false";

        // FIXME: THIS IS UGLY IT KEEPS UPDATING ON RENDER LOOP!!
        // Change Account State!!!
        this.updateExistingStateF();

      } else if (value.toString() === "true"){
        existing = "true";

        // FIXME: THIS IS UGLY IT KEEPS UPDATING ON RENDER LOOP!!
        // Change Account State!!!
        this.updateExistingStateT();

      }
      // eslint-disable-next-line
      console.log("IS THE PROMISE EXISTING???: " + existing);

    });

    // eslint-disable-next-line
    console.log("IS IT EXISTING???: " + this.state.isExisting);

    let flagExisting;

    // FIXME: NEED TO PREVENT A NEW USER REGISTRATION ON EXISTING ACCOUNT
    if (!this.state.isExisting) {
      flagExisting = "error-class";
      // eslint-disable-next-line
      console.log("qatCrmId DOES NOT EXISTS IN DB. THIS IS A NEW REGISTRATION!!!!: " + flagExisting);
    } else {
      flagExisting = "success-class";
      // eslint-disable-next-line
      console.log("qatCrmId ALREADY EXISTS IN DB: " + flagExisting);
    }

    return (
      <div>
        <Media query="(min-width: 319px) and (max-width: 567px)">
          <div>
            <header className="hdr1">
              <h1 className="title">Create a QAT CRM User Account</h1>
              <h3 className="tagline">Step 3: Create your Administrator Login Credentials</h3>
            </header>
            <header className="hdr2">
              <h2 className="title">Create a QAT CRM User Account</h2>
              <h4 className="tagline">Step 3: Create your Admin Login Credentials</h4>
            </header>
            <header className="hdr4">
              <h4 className="title">Create a QAT CRM User Account</h4>
              <h6 className="tagline">Step 3: Create your first Login Credentials</h6>
            </header>
            <section id="progressBar">
              { this.renderProgressBar(66) }
            </section>
            <div className="signup">
              { /*Start Form for step 1 here!!*/ }
              <Form className="form-sm" onSubmit={ this.handleSubmit }>

                <p>
                  <strong>Please Register As a new User.</strong>
                </p>

                <Form.Group controlId="qatCrmId">
                  <Form.Label>
                    QAT CRM ID Number
                  </Form.Label>
                  <Form.Control autoFocus
                    size="lg"
                    type="text"
                    value={ this.state.qatCrmId }
                    onChange={ this.handleChange }
                    required />
                </Form.Group>

                <Form.Group controlId="approvalCode">
                  <Form.Label>
                    Approval Code
                  </Form.Label>
                  <Form.Control autoFocus
                    size="lg"
                    type="text"
                    value={ this.state.approvalCode }
                    onChange={ this.handleChange }
                    placeholder="Use: 'testing_only' for now."
                    required />
                </Form.Group>

                { /* TODO: User Role need to be sure this is first Admin user for qatCrmId!!!!. */ }
                <Form.Group controlId="userRole">
                  <Form.Label>
                    Access Role
                  </Form.Label>
                  <Form.Control autoFocus
                    size="lg"
                    type="text"
                    value={ this.state.userRole }
                    placeholder="Ex: Administrator"
                    required />
                </Form.Group>

                { /* TODO: Need to validate against userName in db for uniqueness. */ }
                <Form.Group controlId="userName">
                  <Form.Label>
                    Username
                  </Form.Label>
                  <Form.Control autoFocus
                    size="lg"
                    type="text"
                    value={ this.state.userName }
                    onChange={ this.handleChange }
                    placeholder="Ex: username"
                    required />
                </Form.Group>

                <Form.Group controlId="email">
                  <Form.Label>
                    Email
                  </Form.Label>
                  <Form.Control autoFocus
                    size="lg"
                    type="email"
                    value={ this.state.email }
                    onChange={ this.handleChange }
                    required />
                </Form.Group>

                <Form.Group controlId="password">
                  <Form.Label>
                    Password
                  </Form.Label>
                  <Form.Control size="lg"
                    type="password"
                    value={ this.state.password }
                    onChange={ this.handleChange }
                    required />
                </Form.Group>
                <Form.Group controlId="confirmPassword">
                  <Form.Label>
                    Confirm Password
                  </Form.Label>
                  <Form.Control size="lg"
                    type="password"
                    value={ this.state.confirmPassword }
                    onChange={ this.handleChange } />
                </Form.Group>
                { /* LoaderButton Component */ }
                <LoaderButton block
                  size="lg"
                  type="submit"
                  disabled={ !this.validateForm() }
                  variant="primary"
                  className="nxtBtn"
                  isLoading={ this.state.isLoading }
                  text="Register"
                  loadingText="Processing..." />
              </Form>
              { /* Section used for credibility seal on larger screens*/ }
              <section id="logo">
                TBD - Section here
              </section>
              { /* Aside used for credibility seal on mobile devices*/ }
              <RegistrationProgressAside orgType={ `${oType}` } registrationStep={ `${registrationStep}` } />
            </div>
          </div>
        </Media>

        <Media query="(min-width: 568px)">
          <div>
            <header className="hdr1">
              <h1 className="title">Create a QAT CRM User Account</h1>
              <h3 className="tagline">Step 3: Create your Administrator Login Credentials</h3>
            </header>
            <header className="hdr2">
              <h2 className="title">Create a QAT CRM User Account</h2>
              <h4 className="tagline">Step 3: Create your Admin Login Credentials</h4>
            </header>
            <header className="hdr4">
              <h4 className="title">Create a QAT CRM User Account</h4>
              <h6 className="tagline">Step 3: Create your first Login Credentials</h6>
            </header>
            <section id="progressBar">
              { this.renderProgressBar(66) }
            </section>
            <div className="signup">
              { /*Start Form for step 1 here!!*/ }
              <Form className="form" onSubmit={ this.handleSubmit }>

                <p>
                  <strong>Please Register As a new User.</strong>
                </p>

                <Form.Group controlId="qatCrmId">
                  <Form.Label>
                    QAT CRM ID Number
                  </Form.Label>
                  <Form.Control autoFocus
                    size="lg"
                    type="text"
                    value={ this.state.qatCrmId }
                    onChange={ this.handleChange }
                    required />
                </Form.Group>

                <Form.Group controlId="approvalCode">
                  <Form.Label>
                    Approval Code
                  </Form.Label>
                  <Form.Control autoFocus
                    size="lg"
                    type="text"
                    value={ this.state.approvalCode }
                    onChange={ this.handleChange }
                    placeholder="Use: 'testing_only' for now."
                    required />
                </Form.Group>

                { /* TODO: User Role need to be sure this is first Admin user for qatCrmId!!!!. */ }
                <Form.Group controlId="userRole">
                  <Form.Label>
                    Access Role
                  </Form.Label>
                  <Form.Control autoFocus
                    size="lg"
                    type="text"
                    value={ this.state.userRole }
                    placeholder="Ex: Administrator"
                    required />
                </Form.Group>

                { /* TODO: Need to validate against userName in db for uniqueness. */ }
                <Form.Group controlId="userName">
                  <Form.Label>
                    Username
                  </Form.Label>
                  <Form.Control autoFocus
                    size="lg"
                    type="text"
                    value={ this.state.userName }
                    onChange={ this.handleChange }
                    placeholder="Ex: username"
                    required />
                </Form.Group>

                <Form.Group controlId="email">
                  <Form.Label>
                    Email
                  </Form.Label>
                  <Form.Control autoFocus
                    size="lg"
                    type="email"
                    value={ this.state.email }
                    onChange={ this.handleChange }
                    required />
                </Form.Group>

                <Form.Group controlId="password">
                  <Form.Label>
                    Password
                  </Form.Label>
                  <Form.Control size="lg"
                    type="password"
                    value={ this.state.password }
                    onChange={ this.handleChange }
                    required />
                </Form.Group>
                <Form.Group controlId="confirmPassword">
                  <Form.Label>
                    Confirm Password
                  </Form.Label>
                  <Form.Control size="lg"
                    type="password"
                    value={ this.state.confirmPassword }
                    onChange={ this.handleChange } />
                </Form.Group>
                { /* LoaderButton Component */ }
                <LoaderButton block
                  size="lg"
                  type="submit"
                  disabled={ !this.validateForm() }
                  variant="primary"
                  className="nxtBtn"
                  isLoading={ this.state.isLoading }
                  text="Register"
                  loadingText="Processing..." />
              </Form>
              { /* Section used for credibility seal on larger screens*/ }
              <section id="logo">
                TBD - Section here
              </section>
              { /* Aside used for credibility seal on mobile devices*/ }
              <RegistrationProgressAside orgType={ `${oType}` } registrationStep={ `${registrationStep}` } />
            </div>
          </div>
        </Media>
      </div>
      );

  }

  /*
   *
   * @return <UserConfirmation />
   *
   * Reuturns the component that
   * will confirm a user's credentials
   *
   */

  renderConfirmationFormNew() {
    // eslint-disable-next-line
    console.log(
      "orgType: " + this.state.orgType + " " +
      "corpName: " + this.state.corpName + " " +
      "govNumber: " + this.state.govNumber + " " +
      "qatCrmId: " + this.state.qatCrmId + " " +
      "streetAddress: " + this.state.streetAddress + " " +
      "addressLine2: " + this.state.addressLine2 + " " +
      "city: " + this.state.city + " " +
      "state: " + this.state.state + " " +
      "zipCode: " + this.state.zipCode + " " +
      "country: " + this.state.country + " " +
      "firstName: " + this.state.firstName + " " +
      "lastName: " + this.state.lastName + " " +
      "title: " + this.state.title + " " +
      "officePhone: " + this.state.officePhone + " " +
      "mobilePhone: " + this.state.mobilePhone + " " +
      "userName: " + this.state.userName + " " +
      "email: " + this.state.email + " " +
      "password: " + this.state.password + " " +
      "password: " + this.state.confirmPassword + " " +
      "password: " + this.state.approvalCode + " " +
      "userRole: " + this.state.userRole
    );

    // FIXME: email and pw are redundant here since user is a prop also
    return (
      <UserConfirmation orgType={ this.state.orgType }
        registrationStep={ this.state.registrationStep }
        email={ this.state.email }
        password={ this.state.password }
        isAuthenticated={ this.props.isAuthenticated }
        userHasAuthenticated={ this.props.userHasAuthenticated }
        user={ this.state } />
      );
  }

  // NOTE: This is being used to get the correct workflow step
  // to render. You have to setState outside of the render
  // "canvas".
  renderForms() {
    let form;

    if (this.state.registrationStep.toString() === "1") {
      form = this.renderStep1();
    } else if (this.state.registrationStep.toString() === "2") {
      form = this.renderStep2(this.state.orgType);
    } else if (this.state.registrationStep.toString() === "3") {
      form = this.renderStep3();
    }

    return (form);

  }

  // TODO: Conditionally render each form element needed!!!
  // conditionally render the registration form or the confirmation form
  // depending on the user's registration state.
  // ? this.renderForms()
  render() {
    // eslint-disable-next-line
    //console.log("THese are child props.isAuthenticated IN UR.js: " + this.props.isAuthenticated);
    // eslint-disable-next-line
    //console.log("THese are child props.userHasAuthenticated IN UR.js: " + this.props.userHasAuthenticated);

    return (
      <div className="Registration">
        { this.state.newUser === null
          ? this.renderForms()
          : this.renderConfirmationFormNew() }
      </div>
      );
  }
}







