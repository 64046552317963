import React, { Component } from "react";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Media from "react-media";
import "./ParallaxMenu.css";

import { Parallax, ParallaxLayer } from "react-spring/renderprops-addons";

//import driverScene from "../img/DriveScene.gif";

//const licensePlate = "../img/PlateImages/LicensePlate2019.png";

//const licensePlate = "../img/DriveScene.gif";
const driverScene = "../img/DriveScene.gif";
const smog = "../img/ParallaxImages/SmogCert.png";
const renewal = "../img/ParallaxImages/RegistrationRenewal.png";
const insurance = "../img/ParallaxImages/Insurance.png";
const title = "../img/ParallaxImages/TitleTransfer.png";
const license = "../img/ParallaxImages/DriversLicense.png";
const batch = "../img/ParallaxImages/BatchRenewals.png";
const banner = "../img/LandingPageBanner1.png";

export default class ParallaxMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true
    };
  }

  render() {
	return (
		<Container className="parallax-container" fluid="True">
			<Media query="(min-width: 319px) and (max-width: 567px)">
				<div>
					<Parallax ref={ref => (this.parallax = ref)} pages={3}>

						<ParallaxLayer offset={0} speed={0} factor={3} style={{ backgroundImage: `url("${ banner }")`, backgroundSize: "cover", backgroundPosition: "center", opacity: ".4" }} />

						<ParallaxLayer offset={0} speed={0.5} factor={3} style={{ backgroundImage: `url("${ driverScene }")`, backgroundSize: "contain", backgroundPosition: "center" }} />

						<ParallaxLayer offset={ 0.49 } speed={ -0.5 } style={{ pointerEvents: "none" }} >
							<Image src={ `${ renewal }` } className="smog-tile" style={{ width: "45%", marginRight: "50%" }} />
						</ParallaxLayer>

						<ParallaxLayer offset={ 0.49 } speed={ -0.5 } style={{ pointerEvents: "none" }} >
							<Image src={ `${ batch }` } className="smog-tile" style={{ width: "45%", marginLeft: "50%" }} />
						</ParallaxLayer>

						<ParallaxLayer offset={ 0.9 } speed={ -0.5 } style={{ pointerEvents: "none" }} >
							<Image src={ `${ insurance }` } className="smog-tile" style={{ width: "45%", marginLeft: "50%" }} />
						</ParallaxLayer>

						<ParallaxLayer offset={ 0.9 } speed={ -0.5 } style={{ pointerEvents: "none" }} >
							<Image src={ `${ title }` } className="smog-tile" style={{ width: "45%", marginRight: "50%" }} />
						</ParallaxLayer>

						<ParallaxLayer offset={ 2.31 } speed={ -0.5 } style={{ pointerEvents: "none" }} >
							<Image src={ `${ license }` } className="smog-tile" style={{ width: "45%", marginRight: "50%" }} />
						</ParallaxLayer>

						<ParallaxLayer offset={ 2.31 } speed={ -0.5 } style={{ pointerEvents: "none" }} >
							<Image src={ `${ smog }` } className="smog-tile" style={{ width: "45%", marginLeft: "50%" }} />
						</ParallaxLayer>
					</Parallax>
				</div>
			</Media>

			<Media query="(min-width: 568px) and (max-width: 1080px)">
				<div>
					<Parallax ref={ref => (this.parallax = ref)} pages={3}>

						<ParallaxLayer offset={0} speed={0} factor={3} style={{ backgroundImage: `url("${ banner }")`, backgroundSize: "cover", backgroundPosition: "center", opacity: ".4" }} />

						<ParallaxLayer offset={0} speed={0.5} factor={3} style={{ backgroundImage: `url("${ driverScene }")`, backgroundSize: "contain", backgroundPosition: "center" }} />

						<ParallaxLayer offset={ 0.49 } speed={ -0.5 } style={{ pointerEvents: "none" }} >
							<Image src={ `${ renewal }` } className="smog-tile" style={{ width: "20%", marginLeft: "25%" }} />
						</ParallaxLayer>

						<ParallaxLayer offset={ 0.49 } speed={ -0.5 } style={{ pointerEvents: "none" }} >
							<Image src={ `${ batch }` } className="smog-tile" style={{ width: "20%", marginLeft: "70%" }} />
						</ParallaxLayer>

						<ParallaxLayer offset={ 0.9 } speed={ -0.5 } style={{ pointerEvents: "none" }} >
							<Image src={ `${ insurance }` } className="smog-tile" style={{ width: "20%", marginLeft: "70%" }} />
						</ParallaxLayer>

						<ParallaxLayer offset={ 0.9 } speed={ -0.5 } style={{ pointerEvents: "none" }} >
							<Image src={ `${ title }` } className="smog-tile" style={{ width: "20%", marginLeft: "25%" }} />
						</ParallaxLayer>

						<ParallaxLayer offset={ 2.31 } speed={ -0.5 } style={{ pointerEvents: "none" }} >
							<Image src={ `${ license }` } className="smog-tile" style={{ width: "20%", marginLeft: "25%" }} />
						</ParallaxLayer>

						<ParallaxLayer offset={ 2.31 } speed={ -0.5 } style={{ pointerEvents: "none" }} >
							<Image src={ `${ smog }` } className="smog-tile" style={{ width: "20%", marginLeft: "70%" }} />
						</ParallaxLayer>
					</Parallax>
				</div>
			</Media>

			<Media query="(min-width: 1081px)">
				<div>
					<Parallax ref={ref => (this.parallax = ref)} pages={3}>

						<ParallaxLayer offset={0} speed={0} factor={3} style={{ backgroundImage: `url("${ banner }")`, backgroundSize: "cover", backgroundPosition: "center", opacity: ".4" }} />

						<ParallaxLayer offset={0} speed={0.5} factor={3} style={{ backgroundImage: `url("${ driverScene }")`, backgroundSize: "contain", backgroundPosition: "center" }} />

						<ParallaxLayer offset={ 0.30 } speed={ -0.5 } style={{ pointerEvents: "none" }} >
							<Image src={ `${ renewal }` } className="smog-tile" style={{ width: "20%", marginLeft: "20%" }} />
						</ParallaxLayer>

						<ParallaxLayer offset={ 0.30 } speed={ -0.5 } style={{ pointerEvents: "none" }} >
							<Image src={ `${ batch }` } className="smog-tile" style={{ width: "20%", marginLeft: "70%" }} />
						</ParallaxLayer>

						<ParallaxLayer offset={ 0.9 } speed={ -0.5 } style={{ pointerEvents: "none" }} >
							<Image src={ `${ insurance }` } className="smog-tile" style={{ width: "20%", marginLeft: "70%" }} />
						</ParallaxLayer>

						<ParallaxLayer offset={ 0.9 } speed={ -0.5 } style={{ pointerEvents: "none" }} >
							<Image src={ `${ title }` } className="smog-tile" style={{ width: "20%", marginLeft: "20%" }} />
						</ParallaxLayer>

						<ParallaxLayer offset={ 2.45 } speed={ -0.5 } style={{ pointerEvents: "none" }} >
							<Image src={ `${ license }` } className="smog-tile" style={{ width: "20%", marginLeft: "20%" }} />
						</ParallaxLayer>

						<ParallaxLayer offset={ 2.45 } speed={ -0.5 } style={{ pointerEvents: "none" }} >
							<Image src={ `${ smog }` } className="smog-tile" style={{ width: "20%", marginLeft: "70%" }} />
						</ParallaxLayer>
					</Parallax>
				</div>
			</Media>
		</Container>
	);
  }
}
