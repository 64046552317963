import React, { Component } from "react";
import { API, Storage } from "aws-amplify";
import { s3Upload } from "../libs/awsLib";
import Form from "react-bootstrap/Form";
import FormCheck from "react-bootstrap/FormCheck";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./CaseRecord.css";
// Using react containers to achieve responsiveness in Medias
import Container from "react-bootstrap/Container";
import Media from "react-media";

export default class CaseRecord extends Component {
  constructor(props) {
    super(props);
    this.file = null;
    this.state = {
      isLoading: null,
      isDeleting: null,
      caseRecord: null,
      qatCrmId: "",
      userId: "",
      caseRecordId: "",
      caseNum: "",
      createdAt: "",
      corpName: "",
      corpContact: "",
      tixStatus: "",
      urgency: "",
      incidentDate: "",
      vin: "",
      plate: "",
      yr: "",
      make: "",
      model: "",
      subject: "",
      content: "",
      attachmentURL: null
    };
  }

  async componentDidMount() {
    try {
      let attachmentURL;
      // TODO: Need to make sure that all state data is here!!!
      const caseRecord = await this.getCaseRecord();
      const { userId, qatCrmId, caseRecordId, caseNum, createdAt, corpName,
              corpContact, tixStatus, urgency, incidentDate, vin, plate, yr,
              make, model, subject, content, attachment
            } = caseRecord;

      if (attachment) {
        attachmentURL = await Storage.vault.get(attachment);
      }

      this.setState({
        caseRecord, userId, qatCrmId, caseRecordId, caseNum,
        createdAt, corpName, corpContact, tixStatus, urgency,
        incidentDate, vin, plate, yr, make, model,
        subject, content, attachmentURL
      });
    } catch ( err ) {
      // eslint-disable-next-line
      alert(err);
    }
  }

  getCaseRecord() {
    return API.get("case-records", `/case-records/${this.props.match.params.qatCrmId}/${this.props.match.params.caseRecordId}`);
  }

  // Validate each of the form fields in the backend
  validateForm() {
    return (this.state.content.length > 0 &&
      this.state.incidentDate.length > 0 &&
      this.state.subject.length > 0 &&
      this.state.caseNum.length > 0
    );
  }

  // format attachmentURL and strip the timestamp added to filename when uploaded
  formatFileName(str) {
    return str.replace(/^\w+-/, "");
  }

  // format incidentDate and strip the time added to the incidentDate
  formatDateNoTime(str) {
    return str.replace(/T.*$/, "");
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleFileChange = event => {
    this.file = event.target.files[0];
  }

  saveCaseRecord(caseRecord) {
    return API.put("case-records", `/case-records/${this.props.match.params.qatCrmId}/${this.props.match.params.caseRecordId}`, {
      body: caseRecord
    });
  }

  handleSubmit = async event => {
    let attachment;

    event.preventDefault();

    if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
      // eslint-disable-next-line
      alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE/1000000} MB.`);
      return;
    }

    this.setState({
      isLoading: true
    });

    try {
      if (this.file) {
        attachment = await s3Upload(this.file);
      }

      await this.saveCaseRecord({
        userId: this.state.userId,
        caseNum: this.state.caseNum,
        qatCrmId: this.state.qatCrmId,
        userName: this.state.userName,
        caseRecordId: this.state.caseRecordId,
        corpName: this.state.corpName,
        corpContact: this.state.corpContact,
        tixStatus: this.state.tixStatus,
        urgency: this.state.urgency,
        incidentDate: this.state.incidentDate,
        vin: this.state.vin,
        plate: this.state.plate,
        yr: this.state.yr,
        make: this.state.make,
        model: this.state.model,
        subject: this.state.subject,
        content: this.state.content,
        attachment: attachment || this.state.caseRecord.attachment
      });

      this.props.history.push("/");
    } catch ( err ) {
      // eslint-disable-next-line
      alert(err);
      this.setState({
        isLoading: false
      });
    }
  }

  deleteCaseRecord() {
    return API.del("case-records", `/case-records/${this.props.match.params.qatCrmId}/${this.props.match.params.caseRecordId}`);
  }

  handleDelete = async event => {
    event.preventDefault();
    // eslint-disable-next-line
    const confirmed = window.confirm("Are you sure you want to delete this case record?");

    if (!confirmed) {
      return;
    }

    this.setState({
      isDeleting: true
    });

    try {
      await this.deleteCaseRecord();
      this.props.history.push("/");
    } catch ( err ) {
      // eslint-disable-next-line
      alert(err);
      this.setState({
        isDeleting: false
      });
    }
  }

  render() {
    return (
      <article>
        <Media query="(min-width: 319px) and (max-width: 567px)">
          <div>
            <p className="existing-case-title-sm">
              <strong>Update Support Case</strong>
            </p>

            <Container className="CaseRecordsList-sm" fluid="True">
              { this.state.caseRecord &&
                <Form className="record-sm" onSubmit={ this.handleSubmit }>

                  { /* This is the Number FG */ }
                  <Form.Group className="case-num-row-sm" as={ Row } controlId="caseNum">
                    <Form.Label column="true" className="case-number-sm">
                      Case Num:
                    </Form.Label>

                    <Col className="number-field-sm">
                      <Form.Control plaintext
                        className="number-field-sm2"
                        readOnly
                        value={ this.state.caseNum } />
                    </Col>
                  </Form.Group>

                  { /* This is the IncidentDate FG */ }
                  <Form.Group className="incident-date-row-sm" as={ Row } controlId="incidentDate">
                    <Form.Label column="true" className="incident-date-sm">
                      Incident Date:
                    </Form.Label>

                    <Col className="date-field-sm">
                      <Form.Control plaintext
                        className="date-field-sm2"
                        readOnly
                        value={ this.formatDateNoTime(this.state.incidentDate) } />
                    </Col>
                  </Form.Group>

                  { /* This is the customer name */ }
                  <Form.Group className="corp-name-row-sm" as={ Row } controlId="corpName">
                    <Form.Label column="true" className="corp-name-label-sm" >
                      Company:
                    </Form.Label>

                    <Col className="corp-name-field-sm">
                        <Form.Control plaintext
                          readOnly
                          className="corp-name-field-sm2"
                          value={ this.state.corpName }
                          onChange={ this.handleChange } />
                    </Col>
                  </Form.Group>

                  { /* This is the customer name */ }
                  <Form.Group className="corp-contact-row"controlId="corpContact">
                    <Form.Label>
                      Contact Name
                    </Form.Label>
                    <Form.Control
                      as="input"
                      className="case-fields"
                      size="lg"
                      value={ this.state.corpContact }
                      onChange={ this.handleChange } />
                    <Form.Text className="text-muted">
                      Ensure that the contact name is current.
                    </Form.Text>
                  </Form.Group>

                  { /* This is the status */ }
                  { /* TODO: This has to `display: ` on smaller`screen!!!*/ }
                  <Form.Group className="tix-status-sm" controlId="tixStatus">
                    <Form.Label>
                      Ticket Status
                    </Form.Label>
                    <Form.Control as="select"
                      size="lg"
                      value={ this.state.tixStatus }
                      onChange={ this.handleChange }
                      required
                      pattern="Not Started|In Progress|Escalated|Re-Opened|Auditing|Complete">
                      <option>
                        Select From List
                      </option>
                      <option>
                        Not Started
                      </option>
                      <option>
                        In Progress
                      </option>
                      <option>
                        Escalated
                      </option>
                      <option>
                        Re-Opened
                      </option>
                      <option>
                        Auditing
                      </option>
                      <option>
                        Complete
                      </option>
                    </Form.Control>
                  </Form.Group>

                  { /* This is the level of urgency for small device!!*/ }
                  { /* TODO: This has to `display: none` when bigger screen!!!*/ }
                  <Form.Group className="urgency-sm" controlId="urgency">
                    <Form.Label>
                      Urgency
                    </Form.Label>
                    <Form.Control as="select"
                      size="lg"
                      value={ this.state.urgency }
                      onChange={ this.handleChange }
                      required
                      pattern="High|Medium|Low">
                      <option>
                        Select From List
                      </option>
                      <option>
                        High
                      </option>
                      <option>
                        Medium
                      </option>
                      <option>
                        Low
                      </option>
                    </Form.Control>
                  </Form.Group>

                  { /* This is the vin */ }
                  <Form.Group controlId="vin">
                    <Form.Label>
                      Vehicle VIN
                    </Form.Label>
                    <Form.Control as="input"
                      className="case-fields"
                      size="lg"
                      value={ this.state.vin }
                      onChange={ this.handleChange } />
                    <Form.Text className="text-muted">
                      Please enter a Vehicle Identification Number.
                    </Form.Text>
                  </Form.Group>

                  { /* This is the plate */ }
                  <Form.Group controlId="plate">
                    <Form.Label>
                      Vehicle License Plate
                    </Form.Label>
                    <Form.Control as="input"
                      className="case-fields"
                      size="lg"
                      value={ this.state.plate }
                      onChange={ this.handleChange } />
                    <Form.Text className="text-muted">
                      Please enter a License Plate Number.
                    </Form.Text>
                  </Form.Group>

                  { /* This is the year */ }
                  <Form.Group controlId="yr">
                    <Form.Label>
                      Vehicle Year
                    </Form.Label>
                    <Form.Control as="input"
                      className="case-fields"
                      size="lg"
                      value={ this.state.yr }
                      onChange={ this.handleChange } />
                    <Form.Text className="text-muted">
                      Please enter the year of the vehicle.
                    </Form.Text>
                  </Form.Group>

                  { /* This is the make */ }
                  <Form.Group controlId="make">
                    <Form.Label>
                      Vehicle Make
                    </Form.Label>
                    <Form.Control as="input"
                      className="case-fields"
                      size="lg"
                      value={ this.state.make }
                      onChange={ this.handleChange } />
                    <Form.Text className="text-muted">
                      Please enter a vehicle make.
                    </Form.Text>
                  </Form.Group>

                  { /* This is the model */ }
                  <Form.Group controlId="model">
                    <Form.Label>
                      Vehicle Model
                    </Form.Label>
                    <Form.Control as="input"
                      className="case-fields"
                      size="lg"
                      value={ this.state.model }
                      onChange={ this.handleChange } />
                    <Form.Text className="text-muted">
                      Please enter a vehicle model.
                    </Form.Text>
                  </Form.Group>

                  { /* This is the Subject FG */ }
                  <Form.Group className="case-subject-row-sm" controlId="subject">
                    <Form.Label className="case-field-subject-sm">
                      Subject
                    </Form.Label>
                    <Form.Control as="input"
                      className="case-field-subject-sm2"
                      size="lg"
                      value={ this.state.subject }
                      onChange={ this.handleChange } />
                  </Form.Group>

                  { /* This is the 1st FG */ }
                  <Form.Group controlId="content">
                    <Form.Label>
                      Case Description
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="6"
                      className="case-description"
                      size="lg"
                      value={ this.state.content }
                      onChange={ this.handleChange } />
                  </Form.Group>

                  { /* This is the 2nd FG */ }
                  { this.state.caseRecord.attachment &&
                    <Form.Group>
                      <Form.Label>
                        Attachment
                      </Form.Label>
                      <FormCheck>
                        <FormCheck.Input isStatic={ true } />
                        <a target="_blank" rel="noopener noreferrer" href={ this.state.attachmentURL }>
                          { this.formatFileName(this.state.caseRecord.attachment) }
                        </a>
                      </FormCheck>
                    </Form.Group>
                  }
                  { /* This is the 3rd FG */ }
                  <Form.Group controlId="file">
                    { !this.state.caseRecord.attachment &&
                      <Form.Label>
                        Attachment
                      </Form.Label> }
                    <Form.Control onChange={ this.handleFileChange } type="file" />
                  </Form.Group>
                  <LoaderButton block
                    size="lg"
                    disabled={ !this.validateForm() }
                    variant="primary"
                    type="submit"
                    isLoading={ this.state.isLoading }
                    text="Save"
                    loadingText="Saving..." />
                  { /* Add a delete button here */ }
                  <LoaderButton block
                    size="lg"
                    disabled={ !this.validateForm() }
                    variant="danger"
                    isLoading={ this.state.isDeleting }
                    onClick={ this.handleDelete }
                    text="Delete"
                    loadingText="Deleting..." />
                </Form>
              }
            </Container>
          </div>
        </Media>

        {/* TODO: Smaller Landscapes */}
        <Media query="(min-width: 568px)">
          <div className="landscape-media-sm">
            <p className="existing-case-title-md">
              <strong>Update Support Case</strong>
            </p>

            <Container className="CaseRecordsList" fluid="True">
              { this.state.caseRecord &&
                <Form className="record-md" onSubmit={ this.handleSubmit }>

                  { /* This is the Number FG */ }
                  <Form.Group className="case-num-row-md" as={ Row } controlId="caseNum">
                    <Form.Label column="true" className="case-number-md">
                      Case Num:
                    </Form.Label>

                    <Col className="number-field-md">
                      <Form.Control plaintext
                        className="number-field-md2"
                        readOnly
                        value={ this.state.caseNum } />
                    </Col>
                  </Form.Group>

                  { /* This is the IncidentDate FG */ }
                  <Form.Group className="incident-date-row-md" as={ Row } controlId="incidentDate">
                    <Form.Label column="true" className="incident-date-md">
                      Incident Date:
                    </Form.Label>

                    <Col className="date-field-md">
                      <Form.Control plaintext
                        className="date-field-md2"
                        readOnly
                        value={ this.formatDateNoTime(this.state.incidentDate) } />
                    </Col>
                  </Form.Group>

                  { /* This is the customer name */ }
                  <Form.Group className="corp-name-row-md" as={ Row } controlId="corpName">
                    <Form.Label className="corp-name-label-md" column="true" >
                      Company:
                    </Form.Label>

                    <Col className="corp-name-field-md">
                        <Form.Control plaintext
                          readOnly
                          className="corp-name-field-md2"
                          value={ this.state.corpName }
                          onChange={ this.handleChange } />
                    </Col>
                  </Form.Group>

                  { /* This is the customer name */ }
                  <Form.Group className="corp-contact-row"controlId="corpContact">
                    <Form.Label>
                      Contact Name
                    </Form.Label>
                    <Form.Control
                      as="input"
                      className="case-fields"
                      size="lg"
                      value={ this.state.corpContact }
                      onChange={ this.handleChange } />
                    <Form.Text className="text-muted">
                      Ensure that the contact name is current.
                    </Form.Text>
                  </Form.Group>

                  { /* This is the status */ }
                  { /* TODO: This has to `display: ` on smaller`screen!!!*/ }
                  <Form.Group className="status-row-md" as={ Row }>
                    <Form.Group column="true" className="tix-status-md" controlId="tixStatus">
                      <Form.Label>
                        Ticket Status
                      </Form.Label>
                      <Form.Control as="select"
                        size="lg"
                        value={ this.state.tixStatus }
                        onChange={ this.handleChange }
                        required
                        pattern="High|Medium|Low">
                        <option>
                          Select From List
                        </option>
                        <option>
                          Not Started
                        </option>
                        <option>
                          In Progress
                        </option>
                        <option>
                          Escalated
                        </option>
                        <option>
                          Re-Opened
                        </option>
                        <option>
                          Auditing
                        </option>
                        <option>
                          Complete
                        </option>
                      </Form.Control>
                    </Form.Group>

                    { /* This is the level of urgency for small device!!*/ }
                    { /* TODO: This has to `display: none` when bigger screen!!!*/ }
                    <Col className="urgency-col-md" >
                      <Form.Group className="urgency-md" controlId="urgency">
                        <Form.Label>
                          Urgency
                        </Form.Label>
                        <Form.Control as="select"
                          size="lg"
                          value={ this.state.urgency }
                          onChange={ this.handleChange }
                          required
                          pattern="High|Medium|Low">
                          <option>
                            Select From List
                          </option>
                          <option>
                            High
                          </option>
                          <option>
                            Medium
                          </option>
                          <option>
                            Low
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Form.Group>

                  <Form.Group className="vin-plate-row" as={ Row }>
                    <Form.Group column="true" className="vin-md" controlId="vin">
                      <Form.Label>
                        Vehicle VIN
                      </Form.Label>
                      <Form.Control as="input"
                        className="case-fields"
                        size="lg"
                        value={ this.state.vin }
                        onChange={ this.handleChange } />
                      <Form.Text className="text-muted">
                        Please enter a Vehicle Identification Number.
                      </Form.Text>
                    </Form.Group>
                    <Col className="plate-col-md" >
                      { /* This is the plate */ }
                      <Form.Group controlId="plate">
                        <Form.Label>
                          Vehicle License Plate
                        </Form.Label>
                        <Form.Control as="input"
                          className="case-fields"
                          size="lg"
                          value={ this.state.plate }
                          onChange={ this.handleChange } />
                        <Form.Text className="text-muted">
                          Please enter a License Plate Number.
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Form.Group>

                  <Form.Group className="year-make-model-row" as={ Row }>
                    { /* This is the year */ }
                    <Form.Group column="true" className="vehicle-year-md" controlId="yr" >
                      <Form.Label>
                        Vehicle Year
                      </Form.Label>
                      <Form.Control as="input"
                        className="case-fields"
                        size="lg"
                        value={ this.state.yr }
                        onChange={ this.handleChange } />
                      <Form.Text className="text-muted">
                        Please enter the year of the vehicle.
                      </Form.Text>
                    </Form.Group>

                    <Col className="vehicle-make-md" >
                      { /* This is the make */ }
                      <Form.Group controlId="make">
                        <Form.Label>
                          Vehicle Make
                        </Form.Label>
                        <Form.Control as="input"
                          className="case-fields"
                          size="lg"
                          value={ this.state.make }
                          onChange={ this.handleChange } />
                        <Form.Text className="text-muted">
                          Please enter a vehicle make.
                        </Form.Text>
                      </Form.Group>
                    </Col>

                    <Col className="vehicle-model-md" >
                      { /* This is the model */ }
                      <Form.Group controlId="model">
                        <Form.Label>
                          Vehicle Model
                        </Form.Label>
                        <Form.Control as="input"
                          className="case-fields"
                          size="lg"
                          value={ this.state.model }
                          onChange={ this.handleChange } />
                        <Form.Text className="text-muted">
                          Please enter a vehicle model.
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Form.Group>

                  { /* This is the Subject FG */ }
                  <Form.Group className="case-subject-row-sm" controlId="subject">
                    <Form.Label className="case-field-subject-sm">
                      Subject
                    </Form.Label>
                    <Form.Control as="input"
                      className="case-field-subject-sm2"
                      size="lg"
                      value={ this.state.subject }
                      onChange={ this.handleChange } />
                  </Form.Group>

                  { /* This is the 1st FG */ }
                  <Form.Group controlId="content">
                    <Form.Label>
                      Case Description
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="6"
                      className="case-description"
                      size="lg"
                      value={ this.state.content }
                      onChange={ this.handleChange } />
                  </Form.Group>

                  { /* This is the 2nd FG */ }
                  { this.state.caseRecord.attachment &&
                    <Form.Group>
                      <Form.Label>
                        Attachment
                      </Form.Label>
                      <FormCheck>
                        <FormCheck.Input isStatic={ true } />
                        <a target="_blank" rel="noopener noreferrer" href={ this.state.attachmentURL }>
                          { this.formatFileName(this.state.caseRecord.attachment) }
                        </a>
                      </FormCheck>
                    </Form.Group>
                  }
                  { /* This is the 3rd FG */ }
                  <Form.Group controlId="file">
                    { !this.state.caseRecord.attachment &&
                      <Form.Label>
                        Attachment
                      </Form.Label> }
                    <Form.Control onChange={ this.handleFileChange } type="file" />
                  </Form.Group>
                  <LoaderButton block
                    size="lg"
                    disabled={ !this.validateForm() }
                    variant="primary"
                    type="submit"
                    isLoading={ this.state.isLoading }
                    text="Save"
                    loadingText="Saving..." />
                  { /* Add a delete button here */ }
                  <LoaderButton block
                    size="lg"
                    disabled={ !this.validateForm() }
                    variant="danger"
                    isLoading={ this.state.isDeleting }
                    onClick={ this.handleDelete }
                    text="Delete"
                    loadingText="Deleting..." />
                </Form>
              }
            </Container>
          </div>
        </Media>





        {/* Bigger Landscapes */}
        <Media query="(min-width: 640px) and (max-width: 767px)">
        </Media>




        {/* iPads */}
        <Media query="(min-width: 768px) and (max-width: 991px)">
        </Media>



        {/* Desktops */}
        <Media query="(min-width: 992px)">
        </Media>

































      </article>
    );
  }
}
















