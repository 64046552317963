import React, { Component } from "react";
//import Image from "react-bootstrap/Image";
//import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
//import Media from "react-media";
import "./TermsAndConditions.css";
import Jumbotron from "react-bootstrap/Jumbotron";
import Footer from "./Footer";
import "./Fees.css";

export default class Fees extends Component {
	render() {
		return (
			<div>
				<article>
					<Jumbotron className="jumbo">
						<Container className="f-container">
							<article className="f-p">
								<h4 className="f-heading">Registration Service Fees</h4>

								<p>
								The Website allows you the ability to make multiple service requests within one transaction. These fees apply to each vehicle for which service has been requested. You hereby agree to be charged the following fees to renew your vehicle registration, request registration replacement, or transfer your vehicle title through QuickAutoTags:
								</p>

								<p>
								<strong>"Service" fees.</strong> Service fees are fees charged by QuickAutoTags for utilizing our Services. Our service fees are as follows:
								</p>
								<ul>
									<li>Registration renewal: $30</li>
									<li>Transfer of title (CA vehicles): $56</li>
									<li>Revived Salvage/Junk: $75</li>
									<li>Legal Transfers: $30</li>
									<li>Lien Sales (Short): $70</li>
									<li>Lien Sales (Long): $100</li>
									<li>Replacement Stickers, Registration Cards, or Plates: $30</li>
									<li>Diligent Efforts: $200 Minimum</li>
									<li>Items Requiring DMV Visits: $75</li>
									<li>VIN verification: $25 (On-Site Inspection)</li>
								</ul>

								<p>
								You understand and agree that once we receive and verify your order we are entitled to our service fees. You further agree that these fees are in addition to any DMV fees, penalties, or taxes which you may owe the California Department of Motor Vehicle (CVC 1685). All service fees are final and non-refundable. Title transfer service fee will apply upon review of uploaded document(s). Balance of title transfer fees will be charged to your credit card after your document(s) are received by QuickAutoTags.
								</p>

								<p>
								<strong>"DMV" and "BPA" fees.</strong> DMV fees ("Registration Fees") are fees which are assessed and charged by the California Department of Motor Vehicles in order to register your vehicle, transfer your vehicle title, or replace your vehicle registration. You agree that we will collect and forward these fees, including DMV BPA fees according to CVC 1685, and any late fees, parking violations, penalties, etc. You understand and agree that there is absolutely no reversal of DMV fees, and as such, DMV fees are not refundable. You agree to pay us in full all DMV fees which we pay on your behalf. Any over-payment or double payment made to the DMV directly by you will require you request and file for a refund from the California Department of Motor Vehicles following the refund procedures set forth by them. You may contact the DMV at (800) 777-0133.
								</p>

								<p>
								<strong>"Credit Card Processing" fees.</strong> QuickAutoTags charges a credit card processing fee of 3.75% on all amounts paid to the Department of Motor Vehicles. These transactions include but are not limited to, orders which require cardholder verification/identification, all title transfers, commercial vehicle registration, any parking or toll violation payment and/or late penalty. Depending on the circumstances of your transaction, this fee requirement may or may not be displayed to you at checkout. If your transaction requires that we collect a credit card processing fee after checkout, we will contact you to obtain your authorization to collect this fee and complete your order.
								</p>

								<p>
								<strong>"Shipping and Handling" fees.</strong> You will be charged shipping and handling fees based on the shipping service you request. Our shipping and handling fees are as follows:
								</p>

								<ul>
									<li>First-Class USPS shipping $1.95</li>
									<li>Next-day before 4:00pm (PST) delivery $14.95</li>
									<li>Next-day before 10:00am (PST) delivery $34.95</li>
									<li>Digital registration card copy (e-Copy) FREE</li>
								</ul>

							</article>
						</Container>
					</Jumbotron>
				</article>
				<section>
					{/* Need the Footer Menu to display new features */}
					<Footer />
				</section>
			</div>
		);
	}
}
