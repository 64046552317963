import React, { Component } from "react";
//import Image from "react-bootstrap/Image";
//import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
//import Media from "react-media";
import "./TermsAndConditions.css";
import Footer from "./Footer";

export default class TermsAndConditions extends Component {
	render() {
		return (
			<div>
				<Container className="pp-container">
					<article className="tc-p">
						<p>
						QuickAutoTags respects the privacy of our users and has developed this Privacy Policy to demonstrate its commitment to protecting your privacy. This Privacy Policy is intended to describe for you, as an individual who is a user of our services, or otherwise provide us with information through various means the information we collect, how that information may be used, with whom it may be shared, and your choices about such uses and disclosures. We ask that you read this Privacy Policy carefully when using our website or services or transacting business with us. By using this website and or our services, you are accepting, without limitation, the practices described in this Privacy Policy.
						</p>

						<h5 className="pp-heading">Information we collect about you</h5>

						<p>
						When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address, mailing address, phone number, vehicle information or other details to help you with your experience and or fullfill your order or service request. We may collect and store any personal information you enter on our website or provide to us in some other manner. This includes identifying information, such as your name, address, email address, phone number, vehicle information, and other personally identifiable information.
						</p>

						<h5 className="pp-heading">How we use your information</h5>

						<p>
						We may use the information we collect about you to:
						</p>

						<ul>
							<li>
							Deliver the products and services which you have requested;
							</li>
							<li>
							Communicate with you with regard to partially completed service requests;
							</li>
							<li>
							Manage your account and provide you with customer support;
							</li>
							<li>
							Communicate with you by e-mail, postal mail, or telephone about products or services that may be of interest to you;
							</li>
							<li>
							Perform research and analysis about your use of, or interest in, our products, services, or those offered by others;
							</li>
							<li>
							Develop and display content and advertising tailored to your interests on our site and other sites;
							</li>
							<li>
							Perform background screening, which may include the use of third parties, on BPA service providers;
							</li>
							<li>
							Enforce our terms and conditions;
							</li>
							<li>
							Publicly-display comments, ratings and reviews and other content provided by you;
							</li>
							<li>
							Manage our business; and
							</li>
							<li>
							Perform functions as otherwise described to you at the time of collection.
							</li>
						</ul>

						<p>
						Payment and financial information. For BPA Service Providers, we may use the payment method provided by you to process payment for any purchases made on our website, enroll you in any discount, and or other programs in which you elect to participate, to pre-qualify you to participate in our BPA provider network, to protect against or identify possible fraudulent transactions, and otherwise as needed to manage our business. For consumers, we may use the credit card information which you provide to process payment for any purchases made on our website.
						</p>

						<h5 className="pp-heading">
						Who we share your information with
						</h5>

						<p>
						We want you to understand when and with whom we may share personal or other information we have collected about you when using QuickAutoTags.com or while using our services. We do not share your personal information with others except as indicated below or when we inform you and give you an opportunity to opt out of having your personal information shared. We may share personal information with:
						</p>
						<p>
						<strong>
						Service providers:</strong> We may share your personal information with authorized service providers that perform certain services on our behalf. These services may include fulfilling orders, processing credit card payments, providing customer service and marketing assistance, delivering orders, supporting our website functionality, performing business and sales analysis, and supporting other features offered through our website. We may also release information to collection and/or credit agencies for past due accounts. These service providers may have access to personal information needed to perform their functions but are not permitted to share or use such information for any other purposes.
						</p>
						<p>
						<strong>
						Business partners:</strong> When you make purchases, reservations or engage in promotions offered through our website or our services, we may share personal information with the businesses with which we partner to offer you those products and or services. When you elect to engage in a particular service provider's offer or program, you authorize us to provide your email address and other information to that service provider.
						</p>
						<p>
						<strong>
						Direct mail partners:</strong> We may share our postal mailing list with selected providers of goods and services that may be of interest to you.
						</p>
						<p>
						<strong>
						Other Situations:</strong> We also may disclose your information:
						</p>
						<p>
						In response to a subpoena or similar investigative demand, a court order, or a request for cooperation from a law enforcement or other government agency; to defend against legal claims; to establish or exercise our legal rights; or as otherwise required by law. In such cases, we may raise or waive any legal objection or right available to us.
						</p>
						<p>
						When we believe disclosure is appropriate in connection with efforts to investigate, prevent, or take other action regarding illegal activity, suspected fraud or other wrongdoing; to comply with applicable law or cooperate with law enforcement; to protect and defend the rights, property or safety of our company, our users, our employees, or others; or to enforce our website terms and conditions or other agreements or policies.
						</p>
						<p>
						In connection with a substantial corporate transaction, such as the sale of our business, a divestiture, merger, consolidation, or asset sale, or in the unlikely event of bankruptcy.
						</p>
						<p>
						For authorized BPA service providers, automobile dealers, and other professional services providers, we may share your business contact information with third parties, including but not limited to, business name, address, telephone number, email address and name of owner or proprietor of the business.
						</p>
						<p>
						Any third parties to whom we may disclose personal information may have their own privacy policies which describe how they use and disclose personal information. Those policies will govern use, handling and disclosure of your personal information once we have shared it with those third parties as described in this Privacy Policy. If you want to learn more about their privacy practices, we encourage you to visit the websites of those third parties. These entities or their servers may be located either inside or outside the United States.
						</p>

						<h5 className="pp-heading">
						Use of Cookies
						</h5>

						<p>
						We use various technologies to collect information from your computer and about your activities on our site, one of which are Cookies. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your Web browser (if you allow) that enables the site's or service provider's systems to recognize your browser and capture and remember certain information. For instance, we use cookies to help us identify and process vehicle registration(s) for which you've requested service. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future. We also automatically collect information from your browser when you visit our website. This information includes your IP address, your browser type and language, access times, the content of any undeleted cookies that your browser previously accepted from us, and the referring website address.
						</p>
						<p>
						We use cookies to:
						</p>

						<ul>
							<li>Help identify and process service requests.</li>
							<li>Understand and save your preferences for future visits.</li>
							<li>Keep track of advertisements.</li>
							<li>Compile aggregate data about site traffic and site interactions in order to offer better site experiences and tools in the future. We may also use trusted third-party services that track this information on our behalf.</li>
						</ul>

						<p>
						You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser settings. Since browsers differ, please look at your browser's Help Menu to learn the correct way to modify your cookies.
						</p>

						<h5 className="pp-heading">
						Third-party disclosure
						</h5>
						<p>
						We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential. We may also release information when it's release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.
						</p>

						<h5 className="pp-heading">
						Third-party links
						</h5>
						<p>
						At our discretion, we may include or offer third-party products or services on our website. These third-party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.
						</p>

						<h5 className="pp-heading">
						California Online Privacy Protection Act
						</h5>
						<p>
						CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law's reach stretches well beyond California to require any person or company in the United States (and conceivably the world) that operates websites collecting Personally Identifiable Information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals or companies with whom it is being shared.
						</p>
						<h5 className="pp-heading">
						According to CalOPPA, we agree to the following:
						</h5>
						<p>
						Users can visit our site anonymously. Once this privacy policy is created, we will add a link to it on our home page or as a minimum, on the first significant page after entering our website. Our Privacy Policy link includes the word 'Privacy' and can easily be found on the page specified above. You will be notified of any Privacy Policy changes:
						</p>
						<ul>
							<li>On our Privacy Policy Page</li>
							<li>By emailing us</li>
						</ul>

						<h5 className="pp-heading">
						CAN SPAM Act
						</h5>

						<p>
						The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough penalties for violations.
						</p>

						<p>
						We collect your email address in order to:
						</p>

						<ul>
							<li>Send information, respond to inquiries, and/or other requests or questions.</li>
							<li>Process orders and to send information and updates pertaining to orders.</li>
							<li>Send you additional information related to your product and/or service.</li>
						</ul>

							<p>
							To be in accordance with CANSPAM, we agree to the following:
							</p>

						<ul>
							<li>Not use false or misleading subjects or email addresses.</li>
							<li>Identify the message as an advertisement in some reasonable way.</li>
							<li>Include the physical address of our business or site headquarters.</li>
							<li>Monitor third-party email marketing services for compliance, if one is used.</li>
							<li>Honor opt-out/unsubscribe requests quickly.</li>
							<li>Allow users to unsubscribe by using the link at the bottom of each email.</li>
						</ul>

						<p>
						If at any time you would like to unsubscribe from receiving future emails, you can email us at:
						support@quickautotags.com and we will promptly remove you from ALL correspondence.
						</p>

						<h5 className="pp-heading">
						Contacting Us
						</h5>

						<p>
						If there are any questions regarding this privacy policy, you may contact us using the information below.
						</p>

						<p>
						QuickAutoTags
						</p>
						<p>
						5586 Mission Blvd.
						</p>
						<p>
						Riverside, CA 92509
						</p>
						<p>
						(951) 409-9091
						</p>

					</article>
				</Container>
				<section>
					{/* Need the Footer Menu to display new features */}
					<Footer />
				</section>
			</div>
		);
	}
}
