import React, { Component/*, Fragment*/ } from "react";
import Media from "react-media";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
// Using react containers to achieve responsiveness in Medias
import Container from "react-bootstrap/Container";
import LoaderButton from "../components/LoaderButton";
import { API, Auth } from "aws-amplify";
import { s3Upload } from "../libs/awsLib";
import config from "../config";
import "./NewCaseRecord.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { SingleDatePicker } from "react-dates";

export default class NewCaseRecord extends Component {
  constructor(props) {
    super(props);
    this.file = null;

    /*
     * The following data will need to be inserted:
     * 1. Customer name - corpName
     * 2. Date ticket was created (automatically generated)
     * 3. Time ticket was created (automatically generated)
     * 4. Have a drop down menu with a “level of urgency”
     * 5. VIN Number
     * 6. plate number
     * 7. Year make and model
     * 8. Case description
     */

    this.state = {
      isLoading: null,
      focused: false,
      qatCrmId: "",
      userName: "",
      corpName: "",
      corpContact: "",
      createdAt: new Date(Date.now()),
      incidentDate: null,
      caseNum: "",
      tixStatus: "Not Started",
      urgency: "",
      vin: "",
      plate: "",
      yr: "",
      make: "",
      model: "",
      subject: "",
      content: ""
    };
  }

  // TODO: must be async call to get last num to handle caseNum logic
  componentDidMount() {
    //NOTE: Get custom:qatCrmId (and others) from cognito stored attributes
    this.getCurrUserData()
      .then((user) => {
        this.setState({
          qatCrmId: user.attributes["custom:qatCrmId"],
          userName: user.attributes["custom:qatUserName"]
        });
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(this.state.qatCrmId);

    this.setState({
      caseNum: "00001",
      isLoading: false
    });
  }

  validateForm() {
    return (this.state.caseNum.length > 0 && this.state.subject.length > 0 && this.state.incidentDate !== "" && this.state.incidentDate !== null && this.state.content.length > 0);
  }

  // TODO: NEED TO GET USER DATA to MAP to permissions etc
  // eslint-disable-next-line
  getCurrUserData = async () => { 
    this.setState({
      isLoading: true
    });
    //let userId;
    //let userName;
    // TODO: logic here
    try {
      return await Auth.currentAuthenticatedUser({
        bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      // eslint-disable-next-line
      });
    } catch ( err ) {
        // This comment disables eslint to allow the alert to go thru
        // eslint-disable-next-line
        alert(err);

        this.setState({
          isLoading: false
        });
    }
  }

  handleChange = event => {
    //this.getLastNumber();
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleFileChange = event => {
    this.file = event.target.files[0];
  }

  handleSubmit = async event => {
    event.preventDefault();

    const max = config.MAX_ATTACHMENT_SIZE / 1000000;

    if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
      // This comment disables eslint to allow the alert to go thru
      // eslint-disable-next-line
      alert(`Please pick a file smaller than ${ max } MB.`);
      return;
    }

    this.setState({
      isLoading: true
    });

    // TODO: Connect to API and persist new records to DB
    // TODO: Call the createCaseRecord() API here to store
    //       new case records in the db.
    // ss[.]c/c/call-the-create-api.html
    // upload-a-file-tos3.html

    try {
      const attachment = this.file
        ? await s3Upload(this.file)
        : null;

      await this.createNewCase({
        attachment,
        caseNum: this.state.caseNum,
        createdAt: this.state.createdAt,
        qatCrmId: this.state.qatCrmId,
        userName: this.state.userName,
        corpName: this.state.corpName,
        corpContact: this.state.corpContact,
        tixStatus: this.state.tixStatus,
        urgency: this.state.urgency,
        incidentDate: this.state.incidentDate,
        vin: this.state.vin,
        plate: this.state.plate,
        yr: this.state.yr,
        make: this.state.make,
        model: this.state.model,
        subject: this.state.subject,
        content: this.state.content
      });

      this.props.history.push("/");

    } catch ( err ) {
      // This comment disables eslint to allow the alert to go thru
      // eslint-disable-next-line
      alert(err);
      this.setState({
        isLoading: false
      });
    }
  }

  // TODO: get the last item number
  /*
  async getLastNumber(){
    const cases = await API.get("case-records", "/case-records")

    console.log(`This is the variables in promise: ${cases[0].caseRecordId}`)

    return null;
  }
  */

  createNewCase(caseRecord) {
    return API.post("case-records", "/case-records", {
      body: caseRecord
    });
  }

  // format incidentDate and strip the time added to the incidentDate
  formatIncidentDate(str) {
    let dy;
    let m = (str.getMonth().toString() + "/");
    let dt = (str.getDate().toString() + "/");
    let y = (str.getFullYear().toString());
    let hr = ((str.getUTCHours() - 7).toString());
    let min = (str.getUTCMinutes().toString());
    let sec = (str.getUTCSeconds().toString());

    switch (str.getDay()) {
      case 0:
        dy = "Sun, ";
        break;
      case 1:
        dy = "Mon, ";
        break;
      case 2:
        dy = "Tues, ";
        break;
      case 3:
        dy = "Wed, ";
        break;
      case 4:
        dy = "Thur., ";
        break;
      case 5:
        dy = "Fri., ";
        break;
      case 6:
        dy = "Sat., ";
        break;
      default:
        break;
    }
    // This comment disables eslint to allow the alert to go thru
    // eslint-disable-next-line
    console.log(dy + m + dt + y + " at " + hr + ":" + min + ":" + sec);
    return (dy + m + dt + y + " at " + hr + ":" + min);
  //return str.replace(/T.*$/, "");
  }

  renderCaseHeaderAndNumber() { }

  render() {
    return (
      <div>
        <p className="new-case-title">
          <strong>Enter Your New Support Case</strong>
        </p>
        <div className="CaseRecordChrome">
          <Form className="record" onSubmit={ this.handleSubmit }>
            { /* This is the case num */ }
            <Form.Group as={ Row } controlId="caseNum">
              <Form.Label className="case-record-number-sm">
                Case Num:
              </Form.Label>
              <Form.Control plaintext
                readOnly
                className="number-field-sm"
                value={ this.state.caseNum } />
            </Form.Group>
            { /* This is the case num */ }
            <Form.Group as={ Row } controlId="caseNum">
              <Form.Label className="case-record-number-md">
                Case Num:
              </Form.Label>
              <Form.Control plaintext
                readOnly
                className="number-field-md"
                value={ this.state.caseNum } />
            </Form.Group>
            { /* This is the case num */ }
            <Form.Group as={ Row } controlId="caseNum">
              <Form.Label column="true" lg className="case-record-number-lg">
                Case Num:
              </Form.Label>
              <Col lg className="number-field-lg1">
                <Form.Control plaintext
                  readOnly
                  className="number-field-lg2"
                  value={ this.state.caseNum } />
              </Col>
            </Form.Group>
            { /* This is the case num */ }
            <Form.Group as={ Row } controlId="caseNum">
              <Form.Label column lg className="case-record-number-xl">
                Case Num:
              </Form.Label>
              <Col lg className="number-field-xl">
                <Form.Control plaintext
                  readOnly
                  className="number-field-xl2"
                  value={ this.state.caseNum } />
              </Col>
            </Form.Group>

            { /* This is the creation date and time */ }
            <Form.Group as={ Row } controlId="createdAt">
              <Form.Label className="date-label-sm">
                Date:
              </Form.Label>
              <Form.Control plaintext
                readOnly
                className="date-field-sm"
                value={ this.formatIncidentDate(this.state.createdAt) } />
            </Form.Group>
            { /* This is the creation date and time */ }
            <Form.Group as={ Row } controlId="createdAt">
              <Form.Label className="date-label-md">
                Date:
              </Form.Label>
              <Form.Control plaintext
                readOnly
                className="date-field-md"
                value={ this.formatIncidentDate(this.state.createdAt) } />
            </Form.Group>

            { /* This is the creation date and time */ }
            <Form.Group as={ Row } controlId="createdAt">
              <Form.Label column="true" lg className="date-label-lg">
                Date:
              </Form.Label>
              <Col lg className="date-field-lg1">
                <Form.Control plaintext
                  readOnly
                  className="date-field-lg"
                  value={ this.formatIncidentDate(this.state.createdAt) } />
              </Col>
            </Form.Group>

            { /* This is the creation date and time */ }
            <Form.Group as={ Row } controlId="createdAt">
              <Form.Label className="date-label-xl">
                Date:
              </Form.Label>
              <Form.Control plaintext
                readOnly
                className="date-field-xl"
                value={ this.formatIncidentDate(this.state.createdAt) } />
            </Form.Group>

            <Media query="(min-width: 319px) and (max-width: 567px)">
              <Container className="company-details" fluid="True">
                { /* This is the customer name */ }
                <Form.Group controlId="corpName">
                  <Form.Label>
                    Company Name
                  </Form.Label>
                  <Form.Control
                    as="input"
                    className="case-fields"
                    size="lg"
                    value={ this.state.corpName }
                    onChange={ this.handleChange } />
                  <Form.Text className="text-muted">
                    Please enter the name of the customer's company.
                  </Form.Text>
                </Form.Group>

                { /* This is the customer name */ }
                <Form.Group controlId="corpContact">
                  <Form.Label>
                    Contact Name
                  </Form.Label>
                  <Form.Control
                    as="input"
                    className="case-fields"
                    size="lg"
                    value={ this.state.corpContact }
                    onChange={ this.handleChange } />
                  <Form.Text className="text-muted">
                    Please enter the name of the contact.
                  </Form.Text>
                </Form.Group>
              </Container>
            </Media>

            <Media query="(min-width: 568px)">
              <Form.Group as={ Row } className="company-details">
                { /* This is the customer name */ }
                <Form.Group column="true" className="company-name" controlId="corpName">
                  <Form.Label>
                    Company Name
                  </Form.Label>
                  <Form.Control
                    as="input"
                    size="lg"
                    value={ this.state.corpName }
                    onChange={ this.handleChange } />
                  <Form.Text className="text-muted">
                    Please enter the name of the customer's company.
                  </Form.Text>
                </Form.Group>
                <Col className="contact-name">
                  { /* This is the customer name */ }
                  <Form.Group controlId="corpContact">
                    <Form.Label>
                      Contact Name
                    </Form.Label>
                    <Form.Control
                      as="input"
                      size="lg"
                      value={ this.state.corpContact }
                      onChange={ this.handleChange } />
                    <Form.Text className="text-muted">
                      Please enter the name of the contact.
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Form.Group>
            </Media>

            { /* This is the level of urgency for small device!!*/ }
            { /* TODO: This has to `display: none` when bigger screen!!!*/ }
            <Form.Group className="urgent-sm" controlId="urgency">
              <Form.Label>
                Urgency
              </Form.Label>
              <Form.Control as="select"
                size="lg"
                value={ this.state.urgency }
                onChange={ this.handleChange }
                required
                pattern="High|Medium|Low">
                <option>
                  Select From List
                </option>
                <option>
                  High
                </option>
                <option>
                  Medium
                </option>
                <option>
                  Low
                </option>
              </Form.Control>
            </Form.Group>

            { /* This is the incident date reported */ }
            <Media query="(min-width: 319px) and (max-width: 567px)">
              <Form.Group className="incident-sm" controlId="incidentDate">
                <Form.Label className="date-label">
                  Incident Date
                </Form.Label>

                { /* This is where I need the date-picker from AirBnb */ }
                <SingleDatePicker
                  id="date-field1" // PropTypes.string.isRequired,
                  small={ false }
                  block={ true }
                  numberOfMonths={ 1 }
                  date={ this.state.incidentDate }
                  onDateChange={ incidentDate => this.setState({ incidentDate }) }
                  isOutsideRange={ () => false }
                  focused={ this.state.focused }
                  onFocusChange={ ({focused}) => this.setState({
                                    focused
                                  }) }
                  openDirection="down"
                  required={ false }
                  hideKeyboardShortcutsPanel={ true }
                />

                <Form.Text className="text-mute">
                  Enter the date the incident occurred. This date cannot be changed later. Make sure you record the correct date of the incident.
                </Form.Text>
              </Form.Group>
            </Media>

            { /* This is the level of urgency */ }
            <Form.Group id="urgent-row-md" as={ Row }>
              <Form.Group column="true"
                xs={ 1 }
                className="urgent-md"
                controlId="urgency">
                <Form.Label>
                  Urgency
                </Form.Label>
                <Form.Control as="select"
                  size="lg"
                  value={ this.state.urgency }
                  onChange={ this.handleChange }
                  required
                  pattern="High|Medium|Low">
                  <option>
                    Select From List
                  </option>
                  <option>
                    High
                  </option>
                  <option>
                    Medium
                  </option>
                  <option>
                    Low
                  </option>
                </Form.Control>
              </Form.Group>

              { /* This is the incident date reported */ }
              <Media query="(min-width: 568px) and (max-width: 767px)">
                <Col className="incident-md">
                  <Form.Group className="incident-date" controlId="incidentDate">
                    <Form.Label className="date-label">
                      Incident Date
                    </Form.Label>

                    { /* This is where I need the date-picker from AirBnb */ }
                    <SingleDatePicker
                      id="date-field2" // PropTypes.string.isRequired,
                      small={ false }
                      block={ true }
                      numberOfMonths={ 1 }
                      date={ this.state.incidentDate }
                      onDateChange={ incidentDate => this.setState({ incidentDate }) }
                      isOutsideRange={ () => false }
                      focused={ this.state.focused }
                      onFocusChange={ ({focused}) => this.setState({
                                        focused
                                      }) }
                      openDirection="down"
                      required={ false }
                      hideKeyboardShortcutsPanel={ true }
                    />

                    <Form.Text className="text-mute">
                      Enter the date the incident occurred. Record it correctly, the date cannot be changed later.
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Media>
            </Form.Group>

            <Media query="(min-width: 319px) and (max-width: 567px)">
              <article>
                { /* This is the vin */ }
                <Form.Group controlId="vin">
                  <Form.Label>
                    Vehicle VIN
                  </Form.Label>
                  <Form.Control as="input"
                    className="case-fields"
                    size="lg"
                    value={ this.state.vin }
                    onChange={ this.handleChange } />
                  <Form.Text className="text-muted">
                    Please enter a Vehicle Identification Number.
                  </Form.Text>
                </Form.Group>

                { /* This is the plate */ }
                <Form.Group controlId="plate">
                  <Form.Label>
                    Vehicle License Plate
                  </Form.Label>
                  <Form.Control as="input"
                    className="case-fields"
                    size="lg"
                    value={ this.state.plate }
                    onChange={ this.handleChange } />
                  <Form.Text className="text-muted">
                    Please enter a License Plate Number.
                  </Form.Text>
                </Form.Group>

                { /* This is the year */ }
                <Form.Group controlId="yr">
                  <Form.Label>
                    Vehicle Year
                  </Form.Label>
                  <Form.Control as="input"
                    className="case-fields"
                    size="lg"
                    value={ this.state.yr }
                    onChange={ this.handleChange } />
                  <Form.Text className="text-muted">
                    Please enter the year of the vehicle.
                  </Form.Text>
                </Form.Group>
                { /* This is the make */ }
                <Form.Group controlId="make">
                  <Form.Label>
                    Vehicle Make
                  </Form.Label>
                  <Form.Control as="input"
                    className="case-fields"
                    size="lg"
                    value={ this.state.make }
                    onChange={ this.handleChange } />
                  <Form.Text className="text-muted">
                    Please enter a vehicle make.
                  </Form.Text>
                </Form.Group>
                { /* This is the model */ }
                <Form.Group controlId="model">
                  <Form.Label>
                    Vehicle Model
                  </Form.Label>
                  <Form.Control as="input"
                    className="case-fields"
                    size="lg"
                    value={ this.state.model }
                    onChange={ this.handleChange } />
                  <Form.Text className="text-muted">
                    Please enter a vehicle model.
                  </Form.Text>
                </Form.Group>
              </article>
            </Media>

            <Media query="(min-width: 568px) and (max-width: 639px)">

              <Container className="container-row" fluid="True">
                <Form.Group className="vehicle-vin-plate" as={ Row }>
                  { /* This is the vin */ }
                  <Form.Group className="col-vin" column="true" controlId="vin">
                    <Form.Label>
                      Vehicle VIN
                    </Form.Label>
                    <Form.Control as="input"
                      className="case-fields"
                      size="lg"
                      value={ this.state.vin }
                      onChange={ this.handleChange } />
                    <Form.Text className="text-muted">
                      Please enter a Vehicle Identification Number.
                    </Form.Text>
                  </Form.Group>

                  <Col className="col-plate" >
                    { /* This is the plate */ }
                    <Form.Group controlId="plate">
                      <Form.Label>
                        Vehicle License Plate
                      </Form.Label>
                      <Form.Control as="input"
                        className="case-fields"
                        size="lg"
                        value={ this.state.plate }
                        onChange={ this.handleChange } />
                      <Form.Text className="text-muted">
                        Please enter a License Plate Number.
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Form.Group>

                <Form.Group className="year-make-model" as={ Row }>
                  { /* This is the year */ }
                  <Form.Group className="vehicle-year" column="true" controlId="yr">
                    <Form.Label>
                      Vehicle Year
                    </Form.Label>
                    <Form.Control as="input"
                      className="case-fields"
                      size="lg"
                      value={ this.state.yr }
                      onChange={ this.handleChange } />
                    <Form.Text className="text-muted">
                      Enter the year of the vehicle.
                    </Form.Text>
                  </Form.Group>
                  <Col className="vehicle-make" >
                    { /* This is the make */ }
                    <Form.Group controlId="make">
                      <Form.Label>
                        Vehicle Make
                      </Form.Label>
                      <Form.Control as="input"
                        className="case-fields"
                        size="lg"
                        value={ this.state.make }
                        onChange={ this.handleChange } />
                      <Form.Text className="text-muted">
                        Please enter a vehicle make.
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col className="vehicle-model">
                    { /* This is the model */ }
                    <Form.Group controlId="model">
                      <Form.Label>
                        Vehicle Model
                      </Form.Label>
                      <Form.Control as="input"
                        className="case-fields"
                        size="lg"
                        value={ this.state.model }
                        onChange={ this.handleChange } />
                      <Form.Text className="text-muted">
                        Please enter a vehicle model.
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Form.Group>
              </Container>
            </Media>

            <Media query="(min-width: 640px) and (max-width: 767px)">
              <Container className="container-row" fluid="True">
                <Form.Group className="vehicle-vin-plate" as={ Row }>
                  { /* This is the vin */ }
                  <Form.Group className="col-vin" column="true" controlId="vin">
                    <Form.Label>
                      Vehicle VIN
                    </Form.Label>
                    <Form.Control as="input"
                      className="case-fields"
                      size="lg"
                      value={ this.state.vin }
                      onChange={ this.handleChange } />
                    <Form.Text className="text-muted">
                      Please enter a Vehicle Identification Number.
                    </Form.Text>
                  </Form.Group>

                  <Col className="col-plate" >
                    { /* This is the plate */ }
                    <Form.Group controlId="plate">
                      <Form.Label>
                        Vehicle License Plate
                      </Form.Label>
                      <Form.Control as="input"
                        className="case-fields"
                        size="lg"
                        value={ this.state.plate }
                        onChange={ this.handleChange } />
                      <Form.Text className="text-muted">
                        Please enter a License Plate Number.
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Form.Group>

                <Form.Group className="year-make-model" as={ Row }>
                  { /* This is the year */ }
                  <Form.Group className="vehicle-year" column="true" controlId="yr">
                    <Form.Label>
                      Vehicle Year
                    </Form.Label>
                    <Form.Control as="input"
                      className="case-fields"
                      size="lg"
                      value={ this.state.yr }
                      onChange={ this.handleChange } />
                    <Form.Text className="text-muted">
                      Enter the year of the vehicle.
                    </Form.Text>
                  </Form.Group>
                  <Col className="vehicle-make" >
                    { /* This is the make */ }
                    <Form.Group controlId="make">
                      <Form.Label>
                        Vehicle Make
                      </Form.Label>
                      <Form.Control as="input"
                        className="case-fields"
                        size="lg"
                        value={ this.state.make }
                        onChange={ this.handleChange } />
                      <Form.Text className="text-muted">
                        Please enter a vehicle make.
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col className="vehicle-model" >
                    { /* This is the model */ }
                    <Form.Group controlId="model">
                      <Form.Label>
                        Vehicle Model
                      </Form.Label>
                      <Form.Control as="input"
                        className="case-fields"
                        size="lg"
                        value={ this.state.model }
                        onChange={ this.handleChange } />
                      <Form.Text className="text-muted">
                        Please enter a vehicle model.
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Form.Group>
              </Container>
            </Media>

            <Media query="(min-width: 768px) and (max-width: 991px)">
              <Container className="container-row" fluid="True">
                { /* This is the level of urgency */ }
                <Form.Group id="urgent-row-lg" as={ Row }>
                  <Form.Group column="true"
                    xs={ 1 }
                    className="urgent-lg"
                    controlId="urgency">
                    <Form.Label>
                      Urgency
                    </Form.Label>
                    <Form.Control as="select"
                      size="lg"
                      value={ this.state.urgency }
                      onChange={ this.handleChange }
                      required
                      pattern="High|Medium|Low">
                      <option>
                        Select From List
                      </option>
                      <option>
                        High
                      </option>
                      <option>
                        Medium
                      </option>
                      <option>
                        Low
                      </option>
                    </Form.Control>
                  </Form.Group>

                  { /* This is the incident date reported */ }
                  <Col className="incident-lg">
                    <Form.Group className="incident-date" controlId="incidentDate">
                      <Form.Label className="date-label">
                        Incident Date
                      </Form.Label>

                      { /* This is where I need the date-picker from AirBnb */ }
                      <SingleDatePicker
                        // PropTypes.string.isRequired,
                        small={ false }
                        block={ true }
                        numberOfMonths={ 1 }
                        date={ this.state.incidentDate }
                        onDateChange={ incidentDate => this.setState({ incidentDate }) }
                        isOutsideRange={ () => false }
                        focused={ this.state.focused }
                        onFocusChange={ ({focused}) => this.setState({
                                          focused
                                        }) }
                        openDirection="down"
                        required={ false }
                        hideKeyboardShortcutsPanel={ true }
                      />

                      <Form.Text className="text-mute">
                        Enter the date the incident occurred. Record it correctly, the date cannot be changed later.
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Form.Group>

                <Form.Group className="vehicle-vin-plate" as={ Row }>
                  { /* This is the vin */ }
                  <Form.Group className="col-vin" column="true" lg={1} controlId="vin">
                    <Form.Label>
                      Vehicle VIN
                    </Form.Label>
                    <Form.Control as="input"
                      className="case-fields"
                      size="lg"
                      value={ this.state.vin }
                      onChange={ this.handleChange } />
                    <Form.Text className="text-muted">
                      Please enter a Vehicle Identification Number.
                    </Form.Text>
                  </Form.Group>

                  <Col className="col-plate" lg>
                    { /* This is the plate */ }
                    <Form.Group controlId="plate">
                      <Form.Label>
                        Vehicle License Plate
                      </Form.Label>
                      <Form.Control as="input"
                        className="case-fields"
                        size="lg"
                        value={ this.state.plate }
                        onChange={ this.handleChange } />
                      <Form.Text className="text-muted">
                        Please enter a License Plate Number.
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Form.Group>

                <Form.Group className="year-make-model" as={ Row }>
                  { /* This is the year */ }
                  <Form.Group className="vehicle-year" column="true" controlId="yr">
                    <Form.Label>
                      Vehicle Year
                    </Form.Label>
                    <Form.Control as="input"
                      className="case-fields"
                      size="lg"
                      value={ this.state.yr }
                      onChange={ this.handleChange } />
                    <Form.Text className="text-muted">
                      Enter the year of the vehicle.
                    </Form.Text>
                  </Form.Group>
                  <Col className="vehicle-make">
                    { /* This is the make */ }
                    <Form.Group controlId="make">
                      <Form.Label>
                        Vehicle Make
                      </Form.Label>
                      <Form.Control as="input"
                        className="case-fields"
                        size="lg"
                        value={ this.state.make }
                        onChange={ this.handleChange } />
                      <Form.Text className="text-muted">
                        Please enter a vehicle make.
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col className="vehicle-model">
                    { /* This is the model */ }
                    <Form.Group controlId="model">
                      <Form.Label>
                        Vehicle Model
                      </Form.Label>
                      <Form.Control as="input"
                        className="case-fields"
                        size="lg"
                        value={ this.state.model }
                        onChange={ this.handleChange } />
                      <Form.Text className="text-muted">
                        Please enter a vehicle model.
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Form.Group>
              </Container>
            </Media>

            <Media query="(min-width: 992px)">
              <Container className="container-row" fluid="True">
                { /* This is the level of urgency */ }
                <Form.Group id="urgent-row-xl" as={ Row }>
                  <Form.Group column="true"
                    xs={ 1 }
                    className="urgent-xl"
                    controlId="urgency">
                    <Form.Label>
                      Urgency
                    </Form.Label>
                    <Form.Control as="select"
                      size="lg"
                      value={ this.state.urgency }
                      onChange={ this.handleChange }
                      required
                      pattern="High|Medium|Low">
                      <option>
                        Select From List
                      </option>
                      <option>
                        High
                      </option>
                      <option>
                        Medium
                      </option>
                      <option>
                        Low
                      </option>
                    </Form.Control>
                  </Form.Group>

                  { /* This is the incident date reported */ }
                  <Col className="incident-xl">
                    <Form.Group className="incident-date" controlId="incidentDate">
                      <Form.Label className="date-label">
                        Incident Date
                      </Form.Label>

                      { /* This is where I need the date-picker from AirBnb */ }
                      <SingleDatePicker
                        // PropTypes.string.isRequired,
                        small={ false }
                        block={ true }
                        numberOfMonths={ 1 }
                        date={ this.state.incidentDate }
                        onDateChange={ incidentDate => this.setState({ incidentDate }) }
                        isOutsideRange={ () => false }
                        focused={ this.state.focused }
                        onFocusChange={ ({focused}) => this.setState({
                                          focused
                                        }) }
                        openDirection="down"
                        required={ false }
                        hideKeyboardShortcutsPanel={ true }
                      />

                      <Form.Text className="text-mute">
                        Enter the date the incident occurred. Record it correctly, the date cannot be changed later.
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Form.Group>

                <Form.Group className="vehicle-vin-plate" as={ Row }>
                  { /* This is the vin */ }
                  <Form.Group className="col-vin" column="true" lg={1} controlId="vin">
                    <Form.Label>
                      Vehicle VIN
                    </Form.Label>
                    <Form.Control as="input"
                      className="case-fields"
                      size="lg"
                      value={ this.state.vin }
                      onChange={ this.handleChange } />
                    <Form.Text className="text-muted">
                      Please enter a Vehicle Identification Number.
                    </Form.Text>
                  </Form.Group>

                  <Col className="col-plate" lg>
                    { /* This is the plate */ }
                    <Form.Group controlId="plate">
                      <Form.Label>
                        Vehicle License Plate
                      </Form.Label>
                      <Form.Control as="input"
                        className="case-fields"
                        size="lg"
                        value={ this.state.plate }
                        onChange={ this.handleChange } />
                      <Form.Text className="text-muted">
                        Please enter a License Plate Number.
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Form.Group>

                <Form.Group className="year-make-model" as={ Row }>
                  { /* This is the year */ }
                  <Form.Group className="vehicle-year" column="true" controlId="yr">
                    <Form.Label>
                      Vehicle Year
                    </Form.Label>
                    <Form.Control as="input"
                      className="case-fields"
                      size="lg"
                      value={ this.state.yr }
                      onChange={ this.handleChange } />
                    <Form.Text className="text-muted">
                      Enter the year of the vehicle.
                    </Form.Text>
                  </Form.Group>
                  <Col className="vehicle-make">
                    { /* This is the make */ }
                    <Form.Group controlId="make">
                      <Form.Label>
                        Vehicle Make
                      </Form.Label>
                      <Form.Control as="input"
                        className="case-fields"
                        size="lg"
                        value={ this.state.make }
                        onChange={ this.handleChange } />
                      <Form.Text className="text-muted">
                        Please enter a vehicle make.
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col className="vehicle-model">
                    { /* This is the model */ }
                    <Form.Group controlId="model">
                      <Form.Label>
                        Vehicle Model
                      </Form.Label>
                      <Form.Control as="input"
                        className="case-fields"
                        size="lg"
                        value={ this.state.model }
                        onChange={ this.handleChange } />
                      <Form.Text className="text-muted">
                        Please enter a vehicle model.
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Form.Group>
              </Container>

            </Media>



            { /* This is the subject or title */ }
            <Form.Group controlId="subject">
              <Form.Label>
                Subject
              </Form.Label>
              <Form.Control as="input"
                className="case-fields"
                size="lg"
                value={ this.state.subject }
                onChange={ this.handleChange } />
              <Form.Text className="text-muted">
                Please enter a title for this support case.
              </Form.Text>
            </Form.Group>
            { /* This is the description of the tix */ }
            <Form.Group controlId="content">
              <Form.Label>
                Case Description
              </Form.Label>
              <Form.Control as="textarea"
                rows="6"
                className="case-description"
                size="lg"
                value={ this.state.content }
                onChange={ this.handleChange } />
              <Form.Text className="text-muted">
                Please enter the description and nature of the customer's problem.
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="file">
              <Form.Label>
                Attachment
              </Form.Label>
              <Form.Control type="file" onChange={ this.handleFileChange } />
              <Form.Text className="text-muted">
                Please include any attachments as needed.
              </Form.Text>
            </Form.Group>
            { /* LoaderButton Component */ }
            <LoaderButton block
              size="lg"
              disabled={ !this.validateForm() }
              variant="primary"
              type="submit"
              isLoading={ this.state.isLoading }
              text="Create"
              loadingText="Creating..." />
          </Form>
        </div>
      </div>
      );
  }
}






